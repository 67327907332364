'use strict';

import gsap from "gsap";
import {getLocalStorage, removeLocalStorage} from "../utils/local-storage";
import Search from "./Search";

const Header = {
    el: null,
    app: null,
    toggle: null,
    init: function () {
        Header.el = document.querySelector('.header');
        Header.app = document.querySelector('.app');

        let params = (new URL(document.location)).searchParams;
        if(params.get('action') && params.get('action')=="logout") { //s'il y a un logout
            removeLocalStorage("cart_expiration_date");//suppression du localstorage
            removeLocalStorage("cart_id");//suppression du localstorage
            removeLocalStorage("token_cart");//suppression du localstorage
            removeLocalStorage("deliveryMode");//suppression du localstorage
            removeLocalStorage("userInfo");//suppression du localstorage
            removeLocalStorage("cart_nb_entry");//suppression du localstorage
            removeLocalStorage("access_code");//suppression du localstorage
            removeLocalStorage("codePrevente");//suppression du localstorage
        }
        if(params.get('action') && params.get('action')=="update") { //s'il y a un update des infos utilisateurs
            removeLocalStorage("deliveryMode");//suppression du localstorage
            removeLocalStorage("userInfo");//suppression du localstorage
        }


        if (Header.el) {
            const menuItems = Header.el.querySelectorAll('.menu >li');
            if (!document.querySelector('.c-plan') && !document.querySelector('.t-account') && !document.querySelector('.page-template-tpl-account-password-reset') && !document.querySelector('.page-template-tpl-account-password-reset-action') && ((!document.querySelector('.t-tunnel') && !document.querySelector('.page-template-tpl-billetterie-identification-type')))) {
                window.addEventListener('scroll', () => Header.handleScroll());
                Header.el.dataset.sticky = false;
                Header.app.dataset.sticky = false;
                Header.app.classList.remove('stickyDefault');
            } else {
                if (document.querySelector('.c-plan') || document.querySelector('.t-account:not(.stickyHeader--)')) {
                    if (window.matchMedia('(max-width:767px)').matches) {
                        Header.el.dataset.sticky = true;
                        Header.app.dataset.sticky = true;
                        Header.app.classList.add('stickyDefault');
                        window.addEventListener('scroll', () => Header.handleScroll(true));
                    } else {
                        window.addEventListener('scroll', () => Header.handleScroll());
                        Header.el.dataset.sticky = false;
                        Header.app.dataset.sticky = false;
                        Header.app.classList.remove('stickyDefault');
                    }
                } else {
                    Header.el.dataset.sticky = true;
                    Header.app.dataset.sticky = true;
                    Header.app.classList.add('stickyDefault');
                    window.addEventListener('scroll', () => Header.handleScroll(true));
                }
            }

            const buttonMenu = Header.el.querySelector('.header__toggle');
            if (buttonMenu) {
                buttonMenu.addEventListener('click', () => {
                    Header.handleMenu(Header.app, menuItems);
                });
            }

            if (window.matchMedia('(min-width:768px)').matches) {
                // Clicked outside dropdown panel
                const el = Header.el.querySelector('.header__menu-main');
                if (el) {
                    window.addEventListener('click', function (event) {
                        const target = event.target || event.srcElement || event.currentTarget;
                        if (Header.app.classList.contains('menuOpened') && !el.contains(target) && !buttonMenu.contains(target)) {
                            Header.handleMenuClose(Header.app, menuItems);
                        }
                    });
                }
            }

            Header.checkButtonCart();
            Header.checkCookies();

            const itemsWithSubmenu = Header.el.querySelectorAll('li.menu-item-has-children');
            if (itemsWithSubmenu && itemsWithSubmenu.length > 0) {
                itemsWithSubmenu.forEach(item => {
                    Header.handleSubmenu(item);
                });
            }

            const searchBtn = Header.el.querySelector('.header__search');
            if (searchBtn) {
                searchBtn.addEventListener('click', function (e) {
                    // console.log('search');
                    e.preventDefault();
                    Header.handleMenuClose(Header.app, Header.el.querySelectorAll('.menu >li'));
                    Search.header(searchBtn.dataset.href);
                });
            }
        }
    },
    handleScroll: function (sticky) {
        if (sticky) {
            if (window.scrollY !== 0) {
                if (Header.app.classList.contains('stickyDefault')) {
                    Header.app.classList.remove('stickyDefault');
                }
            } else {
                Header.app.classList.add('stickyDefault');
            }
        } else {
            Header.el.dataset.sticky = window.scrollY !== 0;
            Header.app.dataset.sticky = window.scrollY !== 0;
        }
    },
    handleMenu: function (app, menuItems) {
        if (app.classList.contains('menuOpened')) {
            Header.handleMenuClose(app, menuItems);
        } else {
            Header.handleMenuOpen(app, menuItems);
        }
    },
    handleMenuOpen: function (app, menuItems) {
        app.classList.add('menuOpened');
        if (menuItems && menuItems.length > 0) {
            menuItems.forEach((li, index) => {
                gsap.set(li, {
                    duration: 0,
                    autoAlpha: 0,
                    y: '-50px',
                });
                gsap.to(li, {
                    duration: 1,
                    autoAlpha: 1,
                    ease: "power4.out",
                    delay: parseFloat(1 + (.2 * index)),
                });
                gsap.to(li, {
                    duration: 1,
                    y: 0,
                    ease: "power4.out",
                    delay: parseFloat(.8 + (.2 * index)),
                });
            });
        }
    },
    handleMenuClose: function (app, menuItems) {
        // console.log('closemenu');
        app.classList.remove('menuOpened');
        if (menuItems && menuItems.length > 0) {
            menuItems.forEach((li, index) => {
                gsap.to(li, {
                    duration: .4,
                    autoAlpha: 0,
                    ease: "power4.out",
                    delay: parseFloat(.15 + (.1 * index)),
                });
                gsap.to(li, {
                    duration: .4,
                    y: '-50px',
                    ease: "power4.out",
                    delay: parseFloat(.1 + (.1 * index)),
                });
            });

            setTimeout((li) => {
                gsap.set(li, {
                    duration: 0,
                    autoAlpha: 0,
                    y: '-50px',
                });
            }, 600);
        }
    },
    handleSubmenu: function (li) {
        li.addEventListener('click', () => {
            const submenu = li.querySelector('.sub-menu');
            li.classList.toggle('active');
            submenu.classList.toggle('active');
        });
    },
    checkButtonCart: function () {
        const buttonCart = Header.el.querySelector('.header__entries-tickets');
        if (buttonCart) {
            if (getLocalStorage('cart_id') && getLocalStorage('token_cart') && getLocalStorage('cart_nb_entry') && getLocalStorage('cart_nb_entry') > 0) {
                buttonCart.classList.add('show--');
            } else {
                buttonCart.classList.remove('show--');
            }
        }
    },
    checkCookies: function () {
        const headerAccountIcon = Header.el.querySelector('.header__entries .myaccount');
        if (headerAccountIcon) {
            if (document.cookie.indexOf('user_logged=') != -1) {
                headerAccountIcon.innerHTML = `<i class="icon-account-logged"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>`;
            } else {
                headerAccountIcon.innerHTML = `<i class="icon-account"></i>`;
            }
        }
    }
};

export default Header;
