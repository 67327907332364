'use strict';

import wrapSvgText from 'wrap-svg-text';

const TitleNeon = {
    els: null,
    init: function (el) {
        if (el) {
            TitleNeon.handler(el);
        } else {
            TitleNeon.els = document.querySelectorAll('.c-neon');
            if (TitleNeon.els && TitleNeon.els.length > 0) {
                TitleNeon.els.forEach(el => {
                    document.addEventListener('DOMContentLoaded', () => {
                        TitleNeon.handler(el);
                    });
                    window.addEventListener('resize', () => {
                        TitleNeon.handler(el);
                    });
                    setTimeout(() => {
                        TitleNeon.handler(el);
                    }, 200);
                });
            }
        }
    },
    handler: function (el) {
        const text = el.querySelector('.c-neon__placeholder');
        const height = el.clientHeight;
        const svg = el.querySelector('.c-neon__svg');

        svg.setAttribute('height', height);

        const defaultText = svg.querySelector('.default');
        const blurText = svg.querySelector('.blur--');

        let lineHeight = 1.15;
        let topShift = 13;
        let fontSize = "";
        let letterSpacing = "";

        // 18 & +
        if (text.innerHTML.length > 17) {
            el.classList.add('smaller--');
            fontSize = "48px";
            letterSpacing = "2.8px";
            if (window.matchMedia('(max-width:374px)').matches) {
                fontSize = "30px";
            }
            if (window.matchMedia('(min-width:992px)').matches) {
                fontSize = "59px";
                letterSpacing = "3px";
            }
            if (window.matchMedia('(min-width:1200px)').matches) {
                fontSize = "76px";
                letterSpacing = "4px";
            }
        }
        // 14 - 17
        if (text.innerHTML.length > 13 && text.innerHTML.length < 18) {
            el.classList.add('medium--');
            fontSize = "58px";
            letterSpacing = "4px";
            if (window.matchMedia('(max-width:374px)').matches) {
                fontSize = "46px";
            }
            if (window.matchMedia('(min-width:992px)').matches) {
                fontSize = "80px";
            }
            if (window.matchMedia('(min-width:1200px)').matches) {
                fontSize = "108px";
            }
        }


        if (window.matchMedia('(min-width:992px)').matches) {
            lineHeight = 1.15;
            topShift = 19;
            defaultText.setAttribute('y', height - 35);
            blurText.setAttribute('y', height - 35);
        } else {
            defaultText.setAttribute('y', height - 32);
            blurText.setAttribute('y', height - 32);
        }

        if (text.innerHTML.length > 13) {
            const width = el.offsetWidth - 16;

            const allPreviousG = svg.querySelectorAll('g');

            // Wrap text in a rectangle, and size the text to fit.
            const wrappedTextBlur = wrapSvgText({
                text: text.innerText,
                style: {
                    fontSize: fontSize,
                    width: width + 'px',
                    whiteSpace: "pre-line",
                    fontFamily: '"obviously-condensed", "Helvetica", sans-serif',
                    textTransform: 'uppercase',
                    lineHeight: lineHeight,
                    letterSpacing:letterSpacing
                }
            });

            const wrappedText = wrapSvgText({
                text: text.innerText,
                style: {
                    fontSize: fontSize,
                    width: width + 'px',
                    whiteSpace: "pre-line",
                    fontFamily: '"obviously-condensed", "Helvetica", sans-serif',
                    textTransform: 'uppercase',
                    lineHeight: lineHeight,
                    letterSpacing:letterSpacing
                }
            });


            /* clear content */
            if (allPreviousG && allPreviousG.length > 0) {
                allPreviousG.forEach(g => {
                    g.remove();
                });
            }
            /* insert wrapped text */
            svg.appendChild(wrappedTextBlur);
            svg.appendChild(wrappedText);

            const allG = svg.querySelectorAll('g');

            let textFirstGroup = allG[0].querySelectorAll('text');
            if (textFirstGroup && textFirstGroup.length > 0) {
                textFirstGroup.forEach(text => {
                    text.setAttribute('x', '15px');
                    const y = text.getAttribute('y');
                    text.setAttribute('y', parseInt(y) + topShift);
                    text.classList.add('c-neon__svg-text');
                    text.classList.add('blur--');
                });
            }


            let textSecondGroup = allG[1].querySelectorAll('text');
            if (textSecondGroup && textSecondGroup.length > 0) {
                textSecondGroup.forEach(text => {
                    text.setAttribute('x', '15px');
                    const y = text.getAttribute('y');
                    text.setAttribute('y', parseInt(y) + topShift);
                    text.classList.add('c-neon__svg-text');
                    text.classList.add('default');
                });
            }
        }
    }
};

export default TitleNeon;
