'use strict';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Scroll = {
    els: null,
    init: function () {
        Scroll.els = document.querySelectorAll('.c-scroll');
        if (Scroll.els && Scroll.els.length > 0) {
            Scroll.els.forEach(el => {
                const nextEl = el.closest('.trigger').nextElementSibling;
                if(nextEl){
                el.addEventListener('click', () => {
                    nextEl.scrollIntoView({
                        behavior: 'smooth', // Defines the transition animation. default: auto
                        block: 'center', // Defines vertical alignment. default: start start|center|end|nearest
                        inline: 'nearest' // Defines horizontal alignment. default: nearest
                    });

                });
                }

                const section = el.closest('.trigger');
                const main = section.parentNode;

                if (section === main.firstElementChild) {
                    gsap.to(el, {
                        scrollTrigger: {
                            trigger: el.closest(".trigger"),
                            toggleActions: "play reset play reset",
                            start: "bottom bottom",
                            // end: "+=500",
                            onUpdate: self => el.style.opacity = 1 - self.progress
                        },
                    });
                }

            });
        }
    },
};

export default Scroll;