'use strict';

import Search from "./Search";

const Pagination = {
    pageVisible: 3, //Nb Impair necessaire
    elt:'',
    currentPage:1,
    init:function() {
        Pagination.elt = document.querySelector('.c-pagination');
        // document.querySelectorAll('.c-pagination').forEach((elt) => {

        // })
        Pagination.elt.querySelector('.c-pagination_prev').addEventListener('click', function (e) {
            e.preventDefault();
            Pagination.changeCurrent(Pagination.currentPage - 1);
        });

        Pagination.elt.querySelector('.c-pagination_next').addEventListener('click', function (e) {
            e.preventDefault();
            Pagination.changeCurrent(parseInt(Pagination.currentPage) + 1);
        });
    },
    getPages: function(nb_pages, currentPage) {
        if(document.getElementById("link_next"))
            document.head.removeChild(document.getElementById("link_next"));
        if(document.getElementById("link_prev"))
            document.head.removeChild(document.getElementById("link_prev"));
        
        Pagination.currentPage = currentPage;
        const pivot = Math.floor(Pagination.pageVisible / 2);
        let pages = [];

        let indexDebut = currentPage - pivot > 1 ? currentPage - pivot : 1;
        // On doit aussi prendre en compte la fin des pages, on va comparer la currentPage et le nb de page total par rapport au pivot
        indexDebut = nb_pages - currentPage < pivot ? nb_pages - Pagination.pageVisible + 1 : indexDebut;
        // Enfin au cas ou il y aurait moins de page que la valeur pageVisible, on fixe indexDebut à 1
        indexDebut = nb_pages <= Pagination.pageVisible ? 1 : indexDebut;

        for(let i = 0; i < Pagination.pageVisible && indexDebut + i <= nb_pages ; i++){
            pages.push(indexDebut+i);
        }

        let html='';
        if(nb_pages > 1) {
            html='<ul>';
            
            if(pages[0] > 1) {
                html+=`<li><button data-page="1">1</button></li>`;
            }
            if(pages[0] > 2) {
                html+=`<li><span>...</span></li>`;
            }
            pages.forEach((i) => {
                html+=`<li><button class="${currentPage == i ? 'current--' : ''}" data-page="${i}">${i}</button></li>`;
            });
            if(!pages.includes(nb_pages-1)) {
                html+=`<li><span>...</span></li>`;
            }

            if(!pages.includes(nb_pages)) {
                html+=`<li><button data-page="${nb_pages}">${nb_pages}</button></li>`;
            }
            html+='</ul>'

            //Affichage ou non du bouton  prev
            if(currentPage > 1){
                Pagination.elt.querySelector('.c-pagination_prev').classList.add('show--');
                Pagination.seoLink('prev', currentPage);
            }
            else {
                Pagination.elt.querySelector('.c-pagination_prev').classList.remove('show--');
            }
            // Affichage ou non du bouton next
            if(currentPage < nb_pages){
                Pagination.elt.querySelector('.c-pagination_next').classList.add('show--');
                Pagination.seoLink('next', currentPage);
            }
            else {
                Pagination.elt.querySelector('.c-pagination_next').classList.remove('show--');
            }

            Pagination.elt.querySelector('.c-pagination__list').innerHTML = html;
            Pagination.elt.classList.add('show--');
            Pagination.eventPagination();
        }
    },
    getPagesMobile: function(nb_pages, currentPage) {
        let html='';
        Pagination.currentPage = currentPage;
        if(nb_pages > 1) {
            html=`<span class="current--">${currentPage}</span> / <span>${nb_pages}</span>`;
            
            //Affichage ou non du bouton  prev
            if(currentPage > 1){
                Pagination.elt.querySelector('.c-pagination_prev').classList.add('show--');
            }
            else {
                Pagination.elt.querySelector('.c-pagination_prev').classList.remove('show--');
            }
            // Affichage ou non du bouton next
            if(currentPage < nb_pages){
                Pagination.elt.querySelector('.c-pagination_next').classList.add('show--');
            }
            else {
                Pagination.elt.querySelector('.c-pagination_next').classList.remove('show--');
            }

            Pagination.elt.querySelector('.c-pagination__list').innerHTML = html;
            Pagination.elt.classList.add('show--');
            // Pagination.eventPagination();
        }
    },
    eventPagination : function() {
        Pagination.elt.querySelectorAll('ul li button').forEach((elt) => {
            elt.addEventListener('click', function (e) {
                e.preventDefault();
                Pagination.changeCurrent(elt.dataset.page);
            });
        });
    },
    getCurrent: function() {
        return Pagination.elt.querySelector('ul li button.current--').dataset.page;
    },
    changeCurrent: function(newPage) {
        Search.updateParams("page_", newPage);
        Search.getParams();
    },
    seoLink: function(rel, currentPage) {
        if(rel && currentPage) {
            let url = document.location.origin+document.location.pathname;
            if(document.location.search) {
                let params = (new URL(document.location)).searchParams;
                if(params.get('month_') && params.get('year_')) {
                    if(params.get('genre_')) {
                        url=url+'?genre_='+params.get('genre_')+'&month_='+params.get('month_')+'&year_='+params.get('year_')+'&';
                    }
                    else {
                        url=url+'?month_='+params.get('month_')+'&year_='+params.get('year_')+'&';
                    }
                }
                else if(params.get('genre_')) {
                    url=url+'?genre_='+params.get('genre_')+'&';
                }
                else {
                    url=url+'?';
                }
            }
            else {
                url=url+'?';
            }

            if(rel === 'next') {
                url=url+'page_='+(parseInt(currentPage)+1);
            } else {
                url=url+'page_='+(currentPage-1);
            }
            const link = document.createElement("link");
            link.rel = rel;
            link.href = url;
            link.id = "link_"+rel;
            document.head.appendChild(link);
        }
    },
    getUrlWithoutPage:function() {
        let url = document.location.path;
        return url;
    }
}
export default Pagination;