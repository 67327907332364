'use strict';

// core version + modules:
import Swiper, {
    Pagination,
    Navigation,
    Autoplay,
    EffectFade,
    Lazy
} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'swiper/css/lazy';

import gsap
    from "gsap";
import ScrollTrigger
    from "gsap/ScrollTrigger";
import TitleNeon
    from "../components/TitleNeon";

gsap.registerPlugin(ScrollTrigger);

const SliderHeader = {
    els: null,
    init: function () {
        SliderHeader.els = document.querySelectorAll('.f-sliderHeader');
        if (SliderHeader.els && SliderHeader.els.length > 0) {
            SliderHeader.els.forEach(el => {
                const slides = el.querySelectorAll('.swiper-slide');
                SliderHeader.create(el, slides.length > 1);
            });
        }
    },
    setPaginationPosition: function (pagination, leftContainer) {
        if (window.matchMedia('(min-width:768px)').matches) {
            pagination.style.left = leftContainer.offsetLeft + 'px';
        }
    },
    create: function (el, moreThanOneSlide) {
        const slider = el.querySelector('.swiper');
        const pagination = el.querySelector('.swiper-pagination');
        if (pagination) {
            const first = el.querySelectorAll('.swiper-slide')[0];
            if (first) {
                const leftContainer = first.querySelector('.f-sliderHeader__item-content');
                SliderHeader.setPaginationPosition(pagination, leftContainer);
                window.addEventListener("resize", () => {
                    SliderHeader.setPaginationPosition(pagination, leftContainer);
                });
            }
        }

        // Manage autoplay when slider is in viewport or not
        function enter() {
            slider.classList.add('autoplay--');
            swiper.autoplay.start();
        }

        function leave() {
            if (slider.classList.contains('autoplay--')) {
                slider.classList.remove('autoplay--');
            }
            swiper.autoplay.pause();
        }

        if (moreThanOneSlide) {
            ScrollTrigger.create({
                trigger: el,
                onEnter: () => enter(),
                onEnterBack: () => enter(),
                onLeave: () => leave(),
                onLeaveBack: () => leave()
            });
        }

        function handlePaginationBullets() {
            const paginationItems = slider.querySelectorAll('.swiper-pagination-bullet');

            if (paginationItems && paginationItems.length > 1) {
                paginationItems.forEach(el => {
                    el.addEventListener('click', () => {
                        slider.classList.remove('autoplay--');
                        slider.classList.add('autoplay-paused--');
                    });
                });
            }
        }

        let params = {};
        if (moreThanOneSlide) {
            params = {
                modules: [Pagination, Navigation, Autoplay, Lazy],
                // effect: "fade",
                // Disable preloading of all images
                preloadImages: false,
                // Enable lazy loading
                lazy: {
                    enabled:true,
                    checkInView:true,
                },
                touchStartPreventDefault: false,
                grabCursor: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true
                },
                spaceBetween: 0,
                slidesPerView: 1,
                loop: true,
                speed: 1,
                autoHeight: true,
                on: {
                    afterInit: (slider) => {
                        setTimeout(() => {
                            TitleNeon.init(slider.el.querySelector('.swiper-slide-active .c-neon'));
                            handlePaginationBullets();
                        }, 500);
                    },
                    slideChangeTransitionStart: (slider) => {
                        TitleNeon.init(slider.el.querySelector('.swiper-slide-active .c-neon'));
                    },
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '"><svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" class="neon-circle">\n' +
                            '    <circle class="neon-circle__item blur--" cx="50%" cy="50%" r="18" fill="none"/>\n' +
                            '    <circle class="neon-circle__item" cx="50%" cy="50%" r="18"  fill="none"/>\n' +
                            '    <circle class="neon-circle__item blur-- blurfront--" cx="50%" cy="50%" r="18" fill="none"/>\n' +
                            '</svg></span>'
                    }
                },
                navigation: {
                    prevEl: ".f-slider__navigation-prev",
                    nextEl: ".f-slider__navigation-next",
                },
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        autoHeight: false
                    }
                }
            }
        } else {
            params = {
                modules: [EffectFade],
                effect: "fade",
                touchStartPreventDefault: false,
                grabCursor: false,
                autoplay: false,
                spaceBetween: 0,
                slidesPerView: 1,
                loop: false,
                speed: 600,
                autoHeight: false,
                on: {
                    afterInit: (slider) => {
                        setTimeout(() => {
                            TitleNeon.init(slider.el.querySelector('.swiper-slide-active .c-neon'));
                        }, 500);
                    },
                    slideChangeTransitionStart: (slider) => {
                        TitleNeon.init(slider.el.querySelector('.swiper-slide-active .c-neon'));
                    }
                },
                pagination: false,
            }
        }


        let swiper = new Swiper(slider, params);
        // if(swiper && slider.querySelector('.f-slider__navigation-next')) {
        //     slider.querySelector('.f-slider__navigation-next').addEventListener('click', () => {
        //         alert('la')
        //     })
        // }
        
    },
};

export default SliderHeader;