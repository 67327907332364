'use strict';

// core version + modules:
import Swiper, {
    Autoplay,
    EffectFade,
    Pagination,
    Lazy
} from "swiper";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import gsap
    from "gsap";
import ScrollTrigger
    from "gsap/ScrollTrigger";
import TitleNeon
    from "../components/TitleNeon";

gsap.registerPlugin(ScrollTrigger);

function getTimeRemaining(endTime) {
    var dateInParis = new Date().toLocaleString('fr-FR', { timeZone: 'Europe/Paris' });
    var parts = dateInParis.split(/\D/);
    const dateInParisFormat = parts[2] + '-' + parts[1] + '-' + parts[0] + ' ' + parts[3] + ':' + parts[4] + ':' + parts[5];
    let total =  Date.parse(endTime) - Date.parse(dateInParisFormat);

    let seconds = Math.floor((total / 1000) % 60);
    let minutes = Math.floor((total / 1000 / 60) % 60);
    let hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    let days = Math.floor(total / (1000 * 60 * 60 * 24));

    if (seconds < 0) {
        seconds = 0;
    }
    if (minutes < 0) {
        minutes = 0;
    }
    if (hours < 0) {
        hours = 0;
    }
    if (days < 0) {
        days = 0;
    }
    return {
        total,
        days,
        hours,
        minutes,
        seconds
    };
}

function timerFunction(iv) {
    let timer = {
        running: false,
        timeout: false,
        cb: function () {
        },
        start: function (cb, iv, sd) {
            var elm = this;
            clearInterval(this.timeout);
            this.running = true;
            if (cb) this.cb = cb;
            if (iv) this.iv = iv;
            if (sd) elm.execute(elm);
            this.timeout = setTimeout(function () {
                elm.execute(elm)
            }, this.iv);
        },
        execute: function (e) {
            if (!e.running) return false;
            e.cb();
            e.start();
        },
        stop: function () {
            this.running = false;
        },
        set_interval: function (iv) {
            clearInterval(this.timeout);
            this.start(false, iv);
        }
    };
    return timer;
}


const Countdown = {
    els: null,
    timeinterval: null,
    timeintervalAnim: null,
    init: function () {
        Countdown.els = document.querySelectorAll('.f-countdown');
        if (Countdown.els && Countdown.els.length > 0) {
            Countdown.els.forEach(el => {
                // Slider
                const slider = el.querySelector('.swiper');
                if (slider) {
                    const slides = el.querySelectorAll('.swiper-slide');
                    Countdown.createSlider(el, slider, slides.length > 1);
                } else {
                    // Header event
                    Countdown.launchClock(el, false);
                }
            });
        }
    },
    createSlider: function (el, slider, moreThanOneSlide) {

        // Manage autoplay when slider is in viewport or not
        function enter() {
            slider.classList.add('autoplay--');
            swiper.autoplay.run();
        }

        function leave() {
            if (slider.classList.contains('autoplay--')) {
                slider.classList.remove('autoplay--');
            }
            swiper.autoplay.pause();
        }

        if (moreThanOneSlide) {
            ScrollTrigger.create({
                trigger: el,
                onEnter: () => enter(),
                onEnterBack: () => enter(),
                onLeave: () => leave(),
                onLeaveBack: () => leave()
            });
        }

        function handlePaginationBullets() {
            const paginationItems = slider.querySelectorAll('.swiper-pagination-bullet');

            if (paginationItems && paginationItems.length > 1) {
                paginationItems.forEach(el => {
                    el.addEventListener('click', () => {
                        slider.classList.remove('autoplay--');
                        slider.classList.add('autoplay-paused--');
                    });
                });
            }
        }

        let params = {};
        if (moreThanOneSlide) {
            params = {
                modules: [Pagination, Autoplay, EffectFade, Lazy],
                effect: "fade",
                // Disable preloading of all images
                preloadImages: false,
                // Enable lazy loading
                lazy: {
                    enabled: true,
                    checkInView: true,
                },
                fadeEffect: {
                    crossFade: true
                },
                touchStartPreventDefault: false,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                spaceBetween: 0,
                slidesPerView: 1,
                loop: true,
                speed: 600,
                autoHeight: false,
                on: {
                    afterInit: (slider) => {
                        Countdown.launchClock(slider.el.querySelector('.swiper-slide-active'));
                        setTimeout(() => {
                            TitleNeon.init(slider.el.querySelector('.swiper-slide-active .c-neon'));
                            handlePaginationBullets();
                        }, 500);
                    },
                    slideChangeTransitionStart: (slider) => {
                        Countdown.launchClock(slider.el.querySelector('.swiper-slide-active'));
                        TitleNeon.init(slider.el.querySelector('.swiper-slide-active .c-neon'));
                    },
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '"><svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" class="neon-circle">\n' +
                            '    <circle class="neon-circle__item blur--" cx="50%" cy="50%" r="18" fill="none" style=""/>\n' +
                            '    <circle class="neon-circle__item" cx="50%" cy="50%" r="18"  fill="none"/>\n' +
                            '    <circle class="neon-circle__item blur-- blurfront--" cx="50%" cy="50%" r="18" fill="none" style=""/>\n' +
                            '</svg></span>'
                    }
                }
            }
        } else {
            params = {
                touchStartPreventDefault: false,
                autoplay: false,
                spaceBetween: 0,
                slidesPerView: 1,
                loop: false,
                speed: 600,
                autoHeight: false,
                lazy: {
                    loadPrevNext: true,
                    checkInView: true
                },
                on: {
                    afterInit: (slider) => {
                        Countdown.launchClock(slider.el.querySelector('.swiper-slide-active'));
                        setTimeout(() => {
                            TitleNeon.init(slider.el.querySelector('.swiper-slide-active .c-neon'));
                        }, 500);
                    },
                    slideChangeTransitionStart: (slider) => {
                        Countdown.launchClock(slider.el.querySelector('.swiper-slide-active'));
                        TitleNeon.init(slider.el.querySelector('.swiper-slide-active .c-neon'));
                    },
                },
                pagination: false
            }
        }
        let swiper = new Swiper(slider, params);
    },
    launchClock: function (slideActive, isSlider = true) {

        if (slideActive) {
            const timerNode = slideActive.querySelector('.f-countdown__item-time');

            // reset previous countdown
            if (Countdown.timeinterval !== null) {
                Countdown.timeinterval.stop();
            }

            if (window.matchMedia('(max-width:767px)').matches && isSlider) {
                // reset previous countdown
                if (Countdown.timeintervalAnim !== null) {
                    Countdown.timeintervalAnim.stop();
                }

                let currentLeftValue = 0;
                if (timerNode.querySelectorAll('.f-countdown__item-time-item').length === 1) {
                    timerNode.appendChild(timerNode.querySelectorAll('.f-countdown__item-time-item')[0].cloneNode(true));
                    timerNode.appendChild(timerNode.querySelectorAll('.f-countdown__item-time-item')[0].cloneNode(true));
                    timerNode.appendChild(timerNode.querySelectorAll('.f-countdown__item-time-item')[0].cloneNode(true));
                    timerNode.appendChild(timerNode.querySelectorAll('.f-countdown__item-time-item')[0].cloneNode(true));
                    timerNode.appendChild(timerNode.querySelectorAll('.f-countdown__item-time-item')[0].cloneNode(true));
                    timerNode.appendChild(timerNode.querySelectorAll('.f-countdown__item-time-item')[0].cloneNode(true));
                    timerNode.appendChild(timerNode.querySelectorAll('.f-countdown__item-time-item')[0].cloneNode(true));
                }

                // Kick off for the animation function.
                Countdown.timeintervalAnim = new timerFunction(1000);
                Countdown.timeintervalAnim.start(function () {
                    animationLoop()
                }, 15, false);

                function animationLoop() {
                    // The part that keeps it all going: animating the margin left value of the list.
                    timerNode.style.marginLeft = `${currentLeftValue}px`;
                    currentLeftValue--;
                }
            }

            if (timerNode) {
                const endDate = timerNode.dataset.open;
                if (endDate) {
                    Countdown.initializeClock(timerNode, endDate, isSlider);
                }
            }
        }
    },
    initializeClock: function (el, endtime, isSlider) {
        const
            daysSpan = el.querySelectorAll('.days'),
            hoursSpan = el.querySelectorAll('.hours'),
            minutesSpan = el.querySelectorAll('.minutes'),
            secondsSpan = el.querySelectorAll('.seconds');

        if (isSlider) {
            Countdown.timeinterval = new timerFunction(1000);
        } else {
            Countdown.timeinterval = new timerFunction(900000);
        }

        function updateClock() {
            if (isSlider) {
                const time = getTimeRemaining(endtime);

                daysSpan.forEach(day => {
                    day.innerHTML = ('0' + time.days).slice(-2);
                });
                hoursSpan.forEach(hour => {
                    hour.innerHTML = ('0' + time.hours).slice(-2);
                });
                minutesSpan.forEach(minute => {
                    minute.innerHTML = ('0' + time.minutes).slice(-2);
                });
                secondsSpan.forEach(second => {
                    second.innerHTML = ('0' + time.seconds).slice(-2);
                });

                if (time.total <= 0) {
                    Countdown.timeinterval.stop();
                }
            } else {
                let date_1 = new Date(endtime);
                let date_2 = new Date();

                let difference = date_1.getTime() - date_2.getTime();
                let calculDays = Math.round(difference / (1000 * 3600 * 24));

                const lang = document.documentElement.lang.substr(0, 2);
                daysSpan.forEach(day => {
                    day.innerHTML = (calculDays > 0 ? calculDays : (lang === "fr" ? 'J' : 'D'));
                });

                if (calculDays <= 0) {
                    Countdown.timeinterval.stop();
                }
                if(calculDays <0 ){
                    el.remove();
                }
            }
        }

        if (isSlider) {

            function enter() {
                updateClock();
                Countdown.timeinterval.start(function () {
                    updateClock();
                }, 1000, false);
            }

            function leave() {
                Countdown.timeinterval.stop();
                if (window.matchMedia('(max-width:767px)').matches) {
                    Countdown.timeintervalAnim.stop();
                }
            }

            // Manage countdown if is in viewport or not
            ScrollTrigger.create({
                trigger: el,
                onEnter: () => enter(),
                onEnterBack: () => enter(),
                onLeave: () => leave(),
                onLeaveBack: () => leave()
            });

        } else {
            updateClock();
            Countdown.timeinterval.start(function () {
                updateClock();
            }, 900000, false); // 15mn
        }
    },
};

export default Countdown;
