'use strict';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// core version + modules:
import Swiper, {Navigation, Lazy} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const Agenda = {
    els: null,
    init: function () {
        Agenda.els = document.querySelectorAll('.f-agenda');
        if (Agenda.els && Agenda.els.length > 0) {
            Agenda.els.forEach(el => {
                Agenda.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = new Swiper(slider, {
            modules: [Navigation, Lazy],
            touchStartPreventDefault: false,
            spaceBetween: 44,
            slidesPerView: 1.15,
            // Disable preloading of all images
            preloadImages: false,
            // Enable lazy loading
            lazy: {
                enabled:true,
                checkInView:true,
                loadPrevNext: true, // pre-loads the next image to avoid showing a loading placeholder if possible
                loadPrevNextAmount: 1
            },
            watchSlidesProgress:true,
            loop: false,
            speed: 600,
            autoHeight: false,
            navigation: {
                prevEl: ".f-agenda__navigation-prev",
                nextEl: ".f-agenda__navigation-next",
            },
            breakpoints: {
                // when window width is >= 540px
                540: {
                    slidesPerView: 1.6,
                },
                // when window width is >= 768px
                768: {
                    slidesPerView: 2.4,
                },
                // when window width is >= 992px
                992: {
                    slidesPerView: 3.2,
                },
                // when window width is >= 1400px
                1400: {
                    slidesPerView: 3.6,
                }
            }
        });
    },
};

export default Agenda;