
export const getDateByMonth = (month, year) => { //retourne la date de debut du mois/annee courant ou date du jour si mois entamé
    //si on est ds le mois courant affichage a partir de la date du jour sinon le 1er jour du mois
    let dateBegin = year+'-'+month+'-01';
    const todayMonth = new Date().getMonth()+1,
    todayYear = new Date().getFullYear(),
    todayDate = new Date().getFullYear()+'-'+String(parseInt(new Date().getMonth()+1)).padStart(2, '0')+"-"+String(new Date().getDate()).padStart(2, '0');

    if(todayMonth == month && todayYear == year) { 
        dateBegin = todayDate;
    }
    return dateBegin;
}

export const firstLastDayOfWeek = () => {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    const todayDate = new Date().getFullYear()+'-'+String(parseInt(new Date().getMonth()+1)).padStart(2, '0')+"-"+String(new Date().getDate()).padStart(2, '0');

    var firstday = new Date(curr.setDate(first)).getFullYear()+"-"+String(parseInt(new Date(curr.setDate(first)).getMonth()+1)).padStart(2, '0')+"-"+String(parseInt(new Date(curr.setDate(first)).getDate())).padStart(2, '0');
    var lastday = new Date(curr.setDate(last)).getFullYear()+"-"+String(parseInt(new Date(curr.setDate(last)).getMonth()+1)).padStart(2, '0')+"-"+String(parseInt(new Date(curr.setDate(last)).getDate())).padStart(2, '0');

    if(firstday < todayDate) {
        firstday = todayDate;
    }

    return {"begin": firstday, "end": lastday};
}

export const firstLastDayOfMonth = (month='') => {
    var curr = new Date(); // get current date
    if(month) {
        curr = new Date(month+"-01");
    }
    var last = new Date(curr.getFullYear(), curr.getMonth() + 1, 0); // get current date
    
    return {"begin": getDateByMonth(String(parseInt(curr.getMonth() + 1)).padStart(2, '0'), curr.getFullYear()), "end": last.getFullYear()+"-"+String(parseInt(last.getMonth()+1)).padStart(2, '0')+"-"+last.getDate()};
}
export const getTodayDate = () => { //retourne la date du jour au format YYYY-mm-dd
    const todayDate = new Date().getFullYear()+'-'+String(parseInt(new Date().getMonth()+1)).padStart(2, '0')+"-"+String(new Date().getDate()).padStart(2, '0');

    return todayDate;
}