
// Slugify a string
export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, '');

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    let from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    let to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by -
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-');

    return str;
}

export const price = (value) => { //value en cents
    value = parseInt(value)/100;
    if(value % 1 !== 0) {//decimal
        value = parseFloat(value).toFixed(2)
    }
    if(value < 0) {
        value= 0;
    }
    return value
}

export const priceFormatString = (value) => { //value en cents
    value = price(value).toString().replace('.', ',');
    return value
}

export const rangePricesString = (array) => {
    const pricesList = [];
    array.forEach(el => {
        pricesList.push(el);
    });

    pricesList.sort((a, b) => a - b);

    const priceMin = pricesList[0];
    const priceMax = pricesList[pricesList.length - 1];

    const prices = priceFormatString(priceMin) + '€ à ' + priceFormatString(priceMax) + '€';

    return prices;
}