
import {priceFormatString} from "../utils/transformText";
const Paiement = {
    cartId: null,
    modeCb:null,
    numCb:null,
    dateCb:null,
    cvvCb:null,
    modeDelivery:null,
    addressDelivery:null,
    formPaiement:null,
    deliveryLivraisonId:[81, 79],//id des livraisons a domicile
    userInfo:null,
    init: function () {
        Paiement.cartId = window.localStorage.getItem('cart_id');
        if(document.querySelector('.t-paiement')) {
            if(Paiement.cartId) {
                

                Paiement.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
                //Preremplissage des champs dans le form
                document.getElementsByName('cartId')[0].value = window.localStorage.getItem('cart_id');
                document.getElementsByName('civility')[0].value = Paiement.userInfo.civility;
                document.getElementsByName('lastname')[0].value = Paiement.userInfo.lastName;
                document.getElementsByName('firstname')[0].value = Paiement.userInfo.firstName;
                document.getElementsByName('EmailPorteur')[0].value = Paiement.userInfo.email;
                document.getElementsByName('userId')[0].value = Paiement.userInfo.userId;
                document.getElementsByName('TotalQuantity')[0].value = window.localStorage.getItem('cart_nb_entry');

                //Preremplissage delivery
                document.getElementsByName('delivery_lastname')[0].value = Paiement.userInfo.deliveryLastName;
                document.getElementsByName('delivery_firstname')[0].value = Paiement.userInfo.deliveryFirstName;
                document.getElementsByName('delivery_address')[0].value = Paiement.userInfo.deliveryAddress;
                document.getElementsByName('delivery_address_additional')[0].value = Paiement.userInfo.deliveryAdditionalAddress;
                document.getElementsByName('delivery_zipcode')[0].value = Paiement.userInfo.deliveryZipCode;
                document.getElementsByName('delivery_city')[0].value = Paiement.userInfo.deliveryCity;
                document.getElementsByName('delivery_countrycode')[0].value = Paiement.userInfo.deliveryCountryId;

                Paiement.modeCb = document.querySelectorAll('.modeCb input');
                Paiement.numCb = document.getElementById('CCNumber');
                Paiement.dateCb = document.getElementById('CCExpDate');
                Paiement.cvvCb = document.getElementById('CVVCode');
                Paiement.modeDelivery = document.querySelector('.t-paiement__delivery');
                Paiement.addressDelivery = document.querySelector('.t-paiement__deliveryAddresses');
                Paiement.formPaiement = document.querySelector('.t-paiement__form');
                
                if (Paiement.modeCb && Paiement.numCb) {
                    Paiement.changeMode();
                    if(document.querySelector('.modeCb input:checked')) {
                        Paiement.getPattern(document.querySelector('.modeCb input:checked').value);
                    }
                }
                if (Paiement.dateCb && Paiement.cvvCb) {
                    // Paiement.dateCb.addEventListener('blur', () => {
                    //     if(Paiement.dateCb.value.length >= 5) {
                    //         Paiement.cvvCb.focus()
                    //     }
                    // });
            
                    Paiement.dateCb.addEventListener('keyup', () => {
                        if(Paiement.dateCb.value.length >= 5) {
                            Paiement.cvvCb.focus()
                        }
                    })
                }
                if(Paiement.modeDelivery) {
                    Paiement.getDeliveryMode();
                }

            }
            else {
                window.location = "/";
            }
        }
    },
    changeMode: function() {
        Paiement.modeCb.forEach((elt) => {
            elt.addEventListener('change', (e) => {
                Paiement.getPattern(e.target.value);
            })
        });
        Paiement.numCb.addEventListener('blur', () => {
            Paiement.numCb.value = Paiement.numCb.value.replace(/[^0-9]/gi, '').replace(/(.{4})/g, '$1 ').trim()
        });

        Paiement.numCb.addEventListener('keyup', (e) => {
            if (e.key >= 0 && e.key <= 9) {
                let nbChars = Paiement.numCb.value.length;
                if(nbChars< 19) {
                    if (nbChars === 4 || nbChars === 9 || nbChars === 14) {
                        Paiement.numCb.value = Paiement.numCb.value+' ';
                    }
                }
                else {
                    Paiement.dateCb.focus();
                }
            } else {
                if (e.keyCode !== 8 && e.keyCode !== 9) {
                    Paiement.numCb.value = Paiement.numCb.value.replace(/[^0-9]/gi, '').replace(/(.{4})/g, '$1 ')
                    e.preventDefault();
                }
            }
        })
    },
    getPattern: function(typeCB) {
        let pattern = '';
        switch (typeCB) {
            case "VISA" :
                pattern = "^4[0-9]{12}(?:[0-9]{3})?$";
                break;
            case "MASTERCARD" :
                pattern = "^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$";
                break;
            case "MAESTRO" :
                pattern = "^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$";
                break;
            case "AMEX": //a reajuster avec la bonne value
                pattern = "^3[47][0-9]{13}$";
                break;
            default:
                pattern =  "^4[0-9]{12}(?:[0-9]{3})?$";
        }
        Paiement.numCb.dataset.checkcb = pattern;
    },
    getDeliveryMode: function() {
        const deliveryMode = JSON.parse(window.localStorage.getItem('deliveryMode'));
        if(deliveryMode) {
            Paiement.modeDelivery.querySelector('.t-paiement__deliveryChoice').innerHTML = `${deliveryMode.label}<span class="price">+${priceFormatString(deliveryMode.price)}€</span>`;
            
            document.getElementById('totalCart').dataset.fdp = deliveryMode.price;

            
                // const deliveryAddress = JSON.parse(window.localStorage.getItem('deliveryAddress'));
                // const billingAddress = JSON.parse(window.localStorage.getItem('billingAddress'));

                // if(deliveryMode.id == "79" || deliveryMode.id == "81") {
                    if(Paiement.userInfo.deliveryAddress && Paiement.userInfo.deliveryZipCode && Paiement.userInfo.deliveryCity) {
                        let address = `${Paiement.userInfo.deliveryFirstName} ${Paiement.userInfo.deliveryLastName}<br />`;
                        address += `${Paiement.userInfo.deliveryAddress}<br />`;
                        if(Paiement.userInfo.deliveryAdditionalAddress) {
                            address +=`${Paiement.userInfo.deliveryAdditionalAddress}<br />`;
                        }
                        address += `${Paiement.userInfo.deliveryZipCode} ${Paiement.userInfo.deliveryCity}<br />${Paiement.userInfo.deliveryCountryName}<br />`;
                        Paiement.addressDelivery.querySelector('.livraison p').innerHTML = address;
                        Paiement.addressDelivery.querySelector('.livraison').classList.add('show--');
                        //Preremplissage des champs dans le form

                        document.getElementsByName('lastname')[0].value = Paiement.userInfo.deliveryLastName;
                        document.getElementsByName('firstname')[0].value = Paiement.userInfo.deliveryFirstName;
                        document.getElementsByName('Address1')[0].value = Paiement.userInfo.deliveryAddress;
                        document.getElementsByName('additional')[0].value = Paiement.userInfo.deliveryAdditionalAddress;
                        document.getElementsByName('ZipCode')[0].value = Paiement.userInfo.deliveryZipCode;
                        document.getElementsByName('City')[0].value = Paiement.userInfo.deliveryCity;
                        document.getElementsByName('CountryCode')[0].value = Paiement.userInfo.deliveryCountryId;
                    }
                    if(Paiement.userInfo.billingAddress && Paiement.userInfo.billingZipCode && Paiement.userInfo.billingCity) {
                        let addressBilling = `${Paiement.userInfo.firstName} ${Paiement.userInfo.lastName}<br />`;
                        addressBilling += `${Paiement.userInfo.billingAddress}<br />`;
                        if(Paiement.userInfo.billingAdditionalAddress) {
                            addressBilling +=`${Paiement.userInfo.billingAdditionalAddress}<br />`;
                        }
                        addressBilling += `${Paiement.userInfo.billingZipCode} ${Paiement.userInfo.billingCity}<br />${Paiement.userInfo.billingCountryName}<br />`;
                        Paiement.addressDelivery.querySelector('.facturation p').innerHTML = addressBilling;
                        Paiement.addressDelivery.querySelector('.facturation').classList.add('show--')

                        document.getElementsByName('lastname')[0].value = Paiement.userInfo.lastName;
                        document.getElementsByName('firstname')[0].value = Paiement.userInfo.firstName;
                        document.getElementsByName('Address1')[0].value = Paiement.userInfo.billingAddress;
                        document.getElementsByName('additional')[0].value = Paiement.userInfo.billingAdditionalAddress;
                        document.getElementsByName('ZipCode')[0].value = Paiement.userInfo.billingZipCode;
                        document.getElementsByName('City')[0].value = Paiement.userInfo.billingCity;
                        document.getElementsByName('CountryCode')[0].value = Paiement.userInfo.billingCountryId;
                    }
                // }
                
                document.getElementsByName('deliveryModeId')[0].value = deliveryMode.id;
                Paiement.modeDelivery.classList.add('show--');
        }
    }
};

export default Paiement;