'use strict';

// core version + modules:
import Swiper, {Navigation, Scrollbar} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

const SliderMulti = {
    els: null,
    init: function () {
        SliderMulti.els = document.querySelectorAll('.f-slider.multi--');
        if (SliderMulti.els && SliderMulti.els.length > 0) {
            SliderMulti.els.forEach(el => {
                const slides = el.querySelectorAll('.swiper-slide');
                SliderMulti.create(el, slides.length);
            });
        }
    },
    create: function (el, slidesLength) {
        const slider = el.querySelector('.swiper');
        let swiper = null;


        let modules = "";
        let navigation = null;
        let scrollbar = null;

        
        if (slider.dataset.navigation === "fleches") {
            swiper = new Swiper(slider, {
                modules: [Navigation],
                touchStartPreventDefault: false,
                spaceBetween: 0,
                slidesPerView: "auto",
                loop: false,
                speed: 600,
                autoHeight: false,
                navigation: {
                    prevEl: ".f-slider__navigation-prev",
                    nextEl: ".f-slider__navigation-next",
                }
            });
        }
        if (slider.dataset.navigation === "scrollbar") {
            swiper = new Swiper(slider, {
                modules: [Scrollbar],
                touchStartPreventDefault: false,
                spaceBetween: 40,
                slidesPerView: "auto",
                loop: false,
                speed: 600,
                autoHeight: false,
                scrollbar: {
                    el: ".f-slider__scrollbar",
                    hide: false,
                    draggable: true
                }
            });
        }

    },
};

export default SliderMulti;