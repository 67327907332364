import {getCartInfos} from "../utils/cart-apis";
import {getLocalStorage} from "../utils/local-storage";
import {slugify, priceFormatString} from "../utils/transformText";
import Accordion from "../blocks/bloc_accordeon";
import Form from "./Form";
import Popin from "./Popin";
import Tunnel from "./Tunnel";
import Plan from "./Plan";

const CartSummary = {
    container: null,
    contentTotalCart:null,
    contentTotalCartPaiement:null,
    tunnelPre:null,
    stickyError:null,
    cartbyEvent: [],
    totalCart:0,
    init: function () {
        const cartId = getLocalStorage('cart_id');
        const token_cart = getLocalStorage('token_cart');
        CartSummary.container = document.querySelector('.c-cartSummary');
        CartSummary.contentTotalCart = document.getElementById('totalCart');
        CartSummary.tunnelPre = document.querySelector('.c-tunnel__pre');
        if(CartSummary.container && !CartSummary.container.classList.contains('noscript--')) {
            if (cartId && token_cart) {
                CartSummary.contentTotalCartPaiement = document.getElementById('totalCartPaiement');
                CartSummary.stickyError = document.querySelector('.c-stickyError');
                getCartInfos(CartSummary.showCartInfos);
            }
            else {
                if(CartSummary.container.dataset.nocart) {
                    Tunnel.showPage();
                    if(CartSummary.tunnelPre) {
                        CartSummary.tunnelPre.classList.add('hide--');
                    }
                    CartSummary.container.innerHTML = `<p class="no-results">${CartSummary.container.dataset.nocart}</p>`;
                }
                else {
                    if(!document.querySelector('.t-account')){
                        window.location = "/";
                    }
                }
            }
        }
    },
    showCartInfos:function(entries, totalAmount=0) {
        if(entries && entries.length > 0) {
            entries = entries.reverse();
            entries.forEach((cart) => {
                if(!CartSummary.cartbyEvent.find(entry => entry.showId === cart.showId)) {
                    CartSummary.cartbyEvent.push({"eventId":cart.eventId, "showId":cart.showId, "eventName": cart.eventName, "eventSlug": cart.eventSlug, "startDateFormated": cart.startDateFormated })
                }
            });
            if(CartSummary.contentTotalCart && CartSummary.cartbyEvent && CartSummary.cartbyEvent.length > 0) {
                let cartHtml = '';
                const personnalisable = JSON.parse(CartSummary.container.dataset.personnalisable);
                const editable = JSON.parse(CartSummary.container.dataset.editable);
                const firstNameEntry = CartSummary.container.dataset.firstname;
                const lastNameEntry = CartSummary.container.dataset.lastname;
                const urlChoicePlace = CartSummary.container.dataset.placeurl;

                CartSummary.cartbyEvent.forEach((eventCart, index) => {
                    const entriesByEvent = entries.filter(entry => entry.showId === eventCart.showId);
                    if(entriesByEvent && entriesByEvent.length > 0 ){
                        cartHtml += `<div class="c-eventList">
                            <div class="container">`;
                                if(editable) {
                                    cartHtml += `<div class="toggle-button rounded--${index === 0 ? " active" : '' }">`;
                                }
                                
                                cartHtml += `<h2 class="c-eventList__title">${eventCart.eventName}</h2>
                                             <p class="c-eventList__date">${eventCart.startDateFormated}</p>`;
                                if(editable) {
                                    cartHtml += `</div>
                                                 <div class="toggle-content${index === 0 ? " active" : '' }">`;
                                }
                                cartHtml += `
                                    <ul class="c-eventList__places">`;
                                        entriesByEvent.forEach((entry) => {
                                            cartHtml += `<li class="c-eventList__place" id="entry-${entry.entryId}" data-entryId="${entry.entryId}" data-price="${entry.priceValueCents}">
                                                <div class="c-place tunnel--">`;
                                                    if(!editable) {
                                                        cartHtml += `<div class="c-place__name"><span class="firstName">${entry.endUserFirstName}</span> <span class="lastName">${entry.endUserLastName}</span></div>`;
                                                    }
                                                    cartHtml += `<div class="c-place__category"><i class="icon-people ${Plan.newClassStyle(slugify(entry.priceCategoryName))}"></i> <span class="${Plan.newClassStyle(slugify(entry.priceCategoryName))}">${entry.section}</span></div>
                                                    <div class="c-place__location">${entry.position}</div>
                                                    <div class="c-place__price">${entry.category} <strong>${entry.priceValueEuros}€</strong></div>`;
                                                    if(editable) {
                                                        cartHtml += `<button class="c-place__delete" type="button" title="Supprimer" data-entryId="${entry.entryId}" data-showId="${entry.showId}">
                                                            <svg id="icon-bin" xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 15.17 20.49"><path d="M.76,4.08H14.45c.17,0,.34-.05,.48-.16,.28-.23,.32-.64,.09-.91-.03-.03-.06-.06-.09-.09-.14-.11-.31-.17-.49-.17h-2.69V.69h0v-.11s0-.03,0-.04c0-.01,0-.02,0-.02,0,0,0-.03-.01-.04,0-.01,0-.01,0-.02,0,0-.01-.03-.02-.04v-.02s-.02-.03-.03-.04h0s-.02-.04-.03-.06h0s-.06-.08-.1-.11c-.14-.11-.31-.17-.49-.17H4.17s-.09,0-.14,.02c-.13,.02-.25,.08-.35,.16-.15,.12-.24,.29-.26,.48V2.73H.75c-.18,0-.35,.07-.49,.17-.15,.12-.24,.29-.25,.48v.04c0,.19,.1,.37,.25,.48,.14,.11,.32,.17,.51,.16ZM4.92,1.35h5.33v1.39H4.92V1.35Z"/><path d="M4.67,8.38c.16,.12,.25,.31,.24,.51v8.18c0,.2-.08,.39-.24,.51-.14,.11-.3,.16-.48,.16h-.02c-.18,0-.36-.05-.5-.16-.15-.12-.24-.29-.25-.48V8.86c.01-.19,.1-.36,.25-.48,.14-.11,.31-.17,.49-.17h.02c.18,0,.35,.07,.49,.17Zm10.5-2.32v.09h0v13.65s0,0,0,.02v.03s0,.02,0,.03,0,.03,0,.04,0,.02,0,.03,0,.03-.01,.04c0,.01,0,.02,0,.03s-.01,.03-.01,.04c0,0,0,.02-.01,.03,0,.01-.01,.03-.02,.03,0,0-.01,.02-.02,.03,0,.01-.02,.02-.02,.03s-.01,.02-.02,.03c0,.01-.02,.02-.02,.03,0,0-.02,.02-.02,.03l-.03,.03s-.02,.02-.03,.03c-.14,.11-.3,.16-.48,.16H.74c-.15,0-.29-.04-.42-.12-.02-.01-.04-.03-.05-.04-.15-.12-.24-.29-.26-.48V6.13c.01-.19,.1-.36,.26-.48,.14-.11,.31-.17,.49-.17H14.45c.18,0,.35,.07,.49,.17,.04,.03,.07,.07,.1,.11,.01,.02,.03,.03,.04,.05h0s.02,.04,.03,.06h0s.01,.05,.02,.06v.02s.02,.04,.02,.05c0,0,0,0,0,.02,0,.02,0,.04,0,.05Zm-1.5,13.08V6.82H1.5v12.32H13.67ZM7.61,8.21h-.03c-.18,0-.35,.07-.49,.17-.15,.12-.24,.29-.25,.48v8.25c.01,.19,.1,.37,.25,.48h0c.14,.11,.32,.17,.5,.16h.02c.17,0,.34-.05,.48-.16,.16-.12,.25-.31,.24-.51V8.89c0-.2-.08-.39-.24-.51-.14-.11-.31-.17-.49-.17Zm3.42,0h-.03c-.18,0-.35,.07-.49,.17-.15,.12-.24,.29-.25,.48v8.25c.01,.19,.1,.37,.25,.48h0c.14,.11,.32,.17,.5,.16h.02c.17,0,.34-.05,.48-.16,.16-.12,.25-.31,.24-.51V8.89c0-.2-.08-.39-.24-.51-.14-.11-.31-.17-.49-.17Z"/></svg>
                                                        </button>`
                                                    }
                                                cartHtml += `</div>`;
                                                if(personnalisable) {
                                                    cartHtml += `<div class="c-eventList__champs">
                                                        <div class="form-row">
                                                            <div class="form-group">
                                                                <label for="firstname-${entry.entryId}">${firstNameEntry}<span class="required">*</span></label>
                                                                <input type="text" id="firstname-${entry.entryId}" name="firstname-${entry.entryId}" placeholder="${firstNameEntry}" class="edit firstName--" required
                                                                value="${entry.endUserFirstName}"/>
                                                                <div class="error" aria-live="polite"></div>
                                                            </div>
                                                        </div>
                                                        <div class="form-row">
                                                            <div class="form-group">
                                                                <label for="lastname-${entry.entryId}">${lastNameEntry}<span class="required">*</span></label>
                                                                <input type="text" id="lastname-${entry.entryId}" name="lastname-${entry.entryId}" placeholder="${lastNameEntry}" class="edit lastName--" required
                                                                value="${entry.endUserLastName}"/>
                                                                <div class="error" aria-live="polite"></div>
                                                            </div>
                                                        </div>
                                                    </div>`;
                                                }
                                            cartHtml += `</li>`;
                                        });
                                cartHtml += `</ul>`;
                                if(editable && urlChoicePlace) {
                                    cartHtml += `<p class="c-eventList__link"><a href="${urlChoicePlace}${eventCart.eventSlug}-${eventCart.eventId}/${eventCart.showId}" class="c-button linkicon-- small--"><i class="icon-map-pin"></i><span>${CartSummary.container.dataset.locate}</span></a></p>
                                    </div>`;
                                }
                            cartHtml += `</div>
                            </div>
                        </div>`;
                    }
                });
                CartSummary.container.innerHTML = cartHtml;

                const fees = CartSummary.checkFdp();
                if(CartSummary.contentTotalCartPaiement) {
                    CartSummary.contentTotalCartPaiement.innerHTML = priceFormatString(parseInt(totalAmount) + fees)+"€";
                }
                CartSummary.contentTotalCart.innerHTML = priceFormatString(parseInt(totalAmount) + fees)+"€";
                
                CartSummary.contentTotalCart.closest('.c-stickyButton').classList.remove('hide--')
                if(!personnalisable) {
                    Accordion.init();
                }
                else {
                    // Check localStorage
                    const cartId = window.localStorage.getItem('cart_id');
                    const token_cart = window.localStorage.getItem('token_cart');
                    const lang = document.documentElement.lang.substr(0, 2);
                    if (process.env.MIX_PERSONNALISATION && lang && cartId && token_cart) {
                        const url = window.location.origin + '/wp-json/' + process.env.MIX_PERSONNALISATION+cartId+ '/token/' + token_cart;
                        Form.manageForm(CartSummary.container.closest('.c-form'), url);
                    }
                }
                CartSummary.event();
            }
            else {
                CartSummary.container.innerHTML = "";
            }
            Tunnel.showPage();
        }
        else {
            if(CartSummary.container.dataset.nocart) {
                if(CartSummary.tunnelPre) {
                    CartSummary.tunnelPre.classList.add('hide--');
                }
                CartSummary.container.innerHTML = `<p class="no-results">${CartSummary.container.dataset.nocart}</p>`;
                CartSummary.contentTotalCart.innerHTML = "0€";
                CartSummary.contentTotalCart.closest('.c-stickyButton').classList.add('hide--');
                Tunnel.showPage();
            }
            else {
                window.location = "/";
            }
        }
    },
    event: function () {
        const btnDelete = CartSummary.container.querySelectorAll('.c-place__delete');
        if(btnDelete && btnDelete.length > 0) {
            btnDelete.forEach(elt => {
                elt.addEventListener('click', (e) => {
                    e.preventDefault();
                    Popin.showPopin(document.getElementById('confirmDeleteEntry'), elt.dataset.showid, elt.dataset.entryid);
                })
            });
        }

        const editElts = CartSummary.container.querySelectorAll('input.edit');
        if(editElts && editElts.length > 0) {
            let timer;
            editElts.forEach((elt) => {
                let eltHtml = elt.closest('.c-eventList__place').querySelector('.c-place__name');
                if(eltHtml) {
                    if(elt.classList.contains('lastName--')) {
                        eltHtml = eltHtml.querySelector('.lastName');
                    }
                    else {
                        eltHtml = eltHtml.querySelector('.firstName');
                    }
                    elt.addEventListener('keyup', (e) => {
                        if (timer){
                            clearTimeout(timer);
                        }
                        timer = setTimeout(function(){
                            eltHtml.innerHTML = CartSummary.cleanHtml(elt.value);
                        }, 1000);
                    });
                    elt.addEventListener('blur', (e) => {
                        eltHtml.innerHTML = CartSummary.cleanHtml(elt.value);
                    });
                }
            })
        }
    },
    cleanHtml: function (str) {
        if ((str===null) || (str==='')) {
            return '';
        }
        else {
            str = str.toString();
            str = str.replace( /(<([^>]+)>)/ig, '');
            // console.log(str)
            return str;
        }
    },
    removeCartInfo: function(item) {
        if(item && item.entryId) {
            const eltToDelete = document.getElementById('entry-'+item.entryId);
            if(eltToDelete) {
                const currentTotalCents = parseFloat(CartSummary.contentTotalCart.innerHTML.replace('€', '').replace(',', '.')) * 100;
                if(eltToDelete.closest('.c-eventList__places').querySelectorAll('li').length > 1) {
                    eltToDelete.remove();
                    CartSummary.contentTotalCart.innerHTML = priceFormatString(currentTotalCents - parseInt(eltToDelete.dataset.price))+"€";
                }
                else {
                    eltToDelete.closest('.c-eventList').remove();
                    CartSummary.contentTotalCart.innerHTML = priceFormatString(currentTotalCents  - parseInt(eltToDelete.dataset.price))+"€";
                    // CartSummary.contentTotalCart.closest('.c-stickyButton').classList.add('hide--')
                    // if(CartSummary.tunnelPre) {
                    //     CartSummary.tunnelPre.classList.add('hide--');
                    // }
                }

                if(CartSummary.container.querySelectorAll('.c-eventList') && CartSummary.container.querySelectorAll('.c-eventList').length == 0) {
                    if(CartSummary.container.dataset.nocart)
                        CartSummary.container.innerHTML = `<p class="no-results">${CartSummary.container.dataset.nocart}</p>`;
                        CartSummary.contentTotalCart.innerHTML = "0€";
                        CartSummary.contentTotalCart.closest('.c-stickyButton').classList.add('hide--')
                        if(CartSummary.tunnelPre) {
                            CartSummary.tunnelPre.classList.add('hide--');
                        }
                }
            }
        }
        Tunnel.showPage();

    },
    showError:function(msg) {
        if(CartSummary.stickyError) {
            CartSummary.stickyError.querySelector('.error.global--').innerHTML = msg;
            CartSummary.stickyError.querySelector('.error.global--').classList.add('active');
        }
    },
    checkFdp: function() {
        const deliveryMode = JSON.parse(window.localStorage.getItem('deliveryMode'));
        let price = 0;
        if(deliveryMode && CartSummary.container.dataset.editable == "false") {
            price = parseInt(deliveryMode.price);
        }
        return price;
    }
};


export default CartSummary;