'use strict';

import {
    slideDown,
    slideUp
} from "../utils/slide-toggle";

function truncateString(str, num) {
    if (str.length > num) {
        return '<span class="excerpt">' + str.slice(0, num) + '<span class="ellipsis">...</span></span><span class="long">' + str.slice(num, str.length) + '</span>';
    } else {
        return str;
    }
}

const PartnersDetails = {
    els: null,
    init: function () {
        PartnersDetails.els = document.querySelectorAll('.f-partnersDetails');
        if (PartnersDetails.els && PartnersDetails.els.length > 0) {
            PartnersDetails.els.forEach(el => {
                const items = el.querySelectorAll('.f-partnersDetails__list-item');
                if (items && items.length > 0) {
                    items.forEach(item => {
                        const text = item.querySelector('p');
                        const longText = text.innerHTML;
                        // 215 characters
                        text.innerHTML = truncateString(longText, 215);

                        const long = text.querySelector('.long');
                        const button = item.querySelector('.toggle-button-icon');
                        if(long){
                            slideUp(long, 0);
                        }

                        if (button && long) {
                            const cursor = document.querySelector('.app >.cursor__wrapper');
                            button.addEventListener('click', () => {
                                if(item.classList.contains('active')){
                                    item.classList.remove('active');

                                    slideUp(long, 400);
                                    if(cursor){
                                        if(cursor.classList.contains('cursor--minus')) {
                                            cursor.classList.remove('cursor--minus');
                                        }
                                        cursor.classList.add('cursor--plus');
                                    }
                                } else {
                                    item.classList.add('active');
                                    slideDown(long, 400);
                                    if(cursor){
                                        cursor.classList.add('cursor--minus');
                                        if(cursor.classList.contains('cursor--plus')){
                                            cursor.classList.remove('cursor--plus');
                                        }
                                    }
                                }
                            });
                        } else {
                            button.classList.add('hidden');
                        }
                    });
                }
            });
        }
    },
};

export default PartnersDetails;
