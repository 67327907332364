'use strict';

import {getCartInfos, getDeliveryMode} from "../utils/cart-apis";
import {priceFormatString} from "../utils/transformText";
import {getLocalStorage} from "../utils/local-storage";
import StickyButton from "./stickyButton";
import Tunnel from "./Tunnel";

const DeliveryMode = {
    eltAccordeon: null,
    typeDeliveryMode: null,
    stickyButtonDefault:null,
    totalPrice:0,
    fees:0,
    totalCartHtml:null,
    countryLivraison : null,
    countryFacturation : null,
    sameAdresseLivraison: null,
    init: function () {
        if(document.querySelector('.t-deliveryMode__list')) {
            const cartId = getLocalStorage('cart_id');
            const token_cart = getLocalStorage('token_cart');
            const deliveryModeStorage = getLocalStorage('deliveryMode');
            const userInfo = getLocalStorage('userInfo');
            if (cartId && token_cart) {
                getCartInfos(DeliveryMode.showPrice);
                getDeliveryMode(DeliveryMode.showDeliveryModes, DeliveryMode.noDeliveryMode);
                DeliveryMode.eltAccordeon = document.querySelector('.t-deliveryMode__list');
                DeliveryMode.typeDeliveryMode = document.getElementById('t-deliveryMode__type');
                DeliveryMode.container = document.querySelector('.stickyContainer--');
                DeliveryMode.stickyButtonDefault = document.getElementById('stickyButtonDefault');
                DeliveryMode.totalCartHtml = document.querySelectorAll('.totalCart');
                DeliveryMode.countryLivraison = document.getElementById('countryLivraison');
                DeliveryMode.countryFacturation = document.getElementById('countryFacturation');
                DeliveryMode.sameAdresseLivraison = document.getElementById('facturationLivraison');
                DeliveryMode.accordeon();
                DeliveryMode.facturation();
                DeliveryMode.event();
                if(deliveryModeStorage) {
                    DeliveryMode.loadDeliveryMode(JSON.parse(deliveryModeStorage), JSON.parse(userInfo));
                }
            }
            else {
                window.location = "/";
            }
        }
        
    },
    event: function() {
        if(DeliveryMode.countryFacturation) {
            const deliveryModeId = DeliveryMode.countryLivraison.closest('form').querySelector('.deliveryModeId');
            const btn = DeliveryMode.countryLivraison.closest('.delivery-livraison').querySelector('.t-deliveryMode__list-item-title');
            DeliveryMode.countryLivraison.addEventListener('change', (e) => {
                if(!DeliveryMode.sameAdresseLivraison.checked) {
                    DeliveryMode.checkCountry(e.target, deliveryModeId, btn);
                }
            });
            DeliveryMode.countryFacturation.addEventListener('change', (e) => {
                if(DeliveryMode.sameAdresseLivraison.checked) {
                    if(e.target.value=="" || e.target.value==0) {
                        DeliveryMode.checkCountry(DeliveryMode.countryLivraison, deliveryModeId, btn);
                    }
                    else {
                        DeliveryMode.checkCountry(e.target, deliveryModeId, btn);
                    }
                }
            })
        }
    },
    checkCountry: function(elt, deliveryModeId, btn) {
        if(elt.value == "1"){//france selectionné
            deliveryModeId.value = 81;
            DeliveryMode.countryLivraison.closest('form').querySelector('.deliveryModeInfos').value = btn.dataset.infoFrance;
            if(btn.dataset.infoFrance) {
                DeliveryMode.majPrice(JSON.parse(btn.dataset.infoFrance).price)
            }
        }
        else if(elt.value != "") {//etranger selectionné
            deliveryModeId.value = 79;
            if(btn.dataset.infoEtranger) {
                DeliveryMode.majPrice(JSON.parse(btn.dataset.infoEtranger).price);
            }
            DeliveryMode.countryLivraison.closest('form').querySelector('.deliveryModeInfos').value = btn.dataset.infoEtranger;
        }
        else {//rien de selectionné
            if(DeliveryMode.countryFacturation && DeliveryMode.countryFacturation.value != "1") {
                deliveryModeId.value = 79;
                DeliveryMode.countryLivraison.closest('form').querySelector('.deliveryModeInfos').value = btn.dataset.infoEtranger;
                if(btn.dataset.infoEtranger) {
                    DeliveryMode.majPrice(JSON.parse(btn.dataset.infoEtranger).price)
                }
            }
            else {
                deliveryModeId.value = 81;
                DeliveryMode.countryLivraison.closest('form').querySelector('.deliveryModeInfos').value = btn.dataset.infoFrance;
                if(btn.dataset.infoFrance) {
                    DeliveryMode.majPrice(JSON.parse(btn.dataset.infoFrance).price)
                }
            }
            
        }
    },
    accordeon: function() {
        const toggleButtons = DeliveryMode.eltAccordeon.querySelectorAll('.t-deliveryMode__list-item-title');
        
        if (toggleButtons && toggleButtons.length > 0) {
            toggleButtons.forEach(button => {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    const toggleContent = button.parentElement.querySelector('.t-deliveryMode__list-item-content');

                    if(!button.classList.contains('active--')) {
                        const activeElts = DeliveryMode.eltAccordeon.querySelectorAll('.active--');
                        if(activeElts && activeElts.length > 0) {
                            activeElts.forEach(elt => {
                                elt.classList.remove('active--');
                                setTimeout(()=> {
                                    elt.classList.remove('show--');
                                }, 200);
                            })
                            DeliveryMode.stickyButtonDefault.classList.remove('hide--');
                        }
                        window.scrollTo({top: DeliveryMode.container.offsetTop });
                        if(e.target.dataset.price) {
                            DeliveryMode.majPrice(e.target.dataset.price)
                        }
                        else {
                            DeliveryMode.majPrice(0)
                        }
                    }
                    else {
                        window.scrollTo({top: 0 });
                        DeliveryMode.majPrice(0);
                    }
                    StickyButton.makeSticky();

                    if(DeliveryMode.eltAccordeon.querySelector('.t-deliveryMode__list-subItem.hidden--')) {
                        const timer = setTimeout(()=> {
                            DeliveryMode.eltAccordeon.querySelector('.t-deliveryMode__list-subItem.hidden--').classList.remove('hidden--');
                            clearTimeout(timer);
                        }, 200);
                    }
                    
                    setTimeout(()=> {
                        if (toggleContent) {
                            if(button.classList.contains('active--')) {
                                setTimeout(()=> {
                                    toggleContent.classList.remove('show--');
                                }, 200);
                            }
                            else {
                                toggleContent.classList.add('show--');
                            }

                            DeliveryMode.stickyButtonDefault.classList.toggle('hide--');
                            button.classList.toggle('active--');
                            toggleContent.classList.toggle('active--');
                        }
                    }, 200)
                    
                });
            });
        }
    },
    facturation: function() {
        const toggleOptin = DeliveryMode.eltAccordeon.querySelectorAll('.checkboxCtn input[data-toggleOption]');
        if(toggleOptin && toggleOptin.length > 0) {
            toggleOptin.forEach((elt) => {
                elt.addEventListener('change', (e) => {
                    e.preventDefault();
                    const contentToggle = document.getElementById(e.target.dataset.toggleoption);
                    if(contentToggle) {
                        if(!e.target.checked) {
                            contentToggle.classList.add('active--');
                            const formGroups = contentToggle.querySelectorAll('.form-group');
                            if (formGroups) {
                                formGroups.forEach(formGroup => {
                                    const input = formGroup.querySelector('input');
                                    const select = formGroup.querySelector('select');
                                    if (input) {
                                        if (input.dataset.required && input.dataset.required == "true") {
                                            input.required = "required";
                                        }
                                    }
                                    if (select) {
                                        if (select.dataset.required && select.dataset.required == "true") {
                                            select.required = "required";
                                        }
                                    }
                                });
                            }
                        }
                        else {
                            contentToggle.classList.remove('active--');
                            const formGroups = contentToggle.querySelectorAll('.form-group');
                            if (formGroups) {
                                formGroups.forEach(formGroup => {
                                    const input = formGroup.querySelector('input');
                                    const select = formGroup.querySelector('select');
                                    if (input) {
                                        input.removeAttribute("required");
                                        input.value="";
                                    }
                                    if (select) {
                                        select.removeAttribute("required");
                                        select.value="";
                                    }
                                });
                            }
                            if(DeliveryMode.countryFacturation) {
                                const deliveryModeId = DeliveryMode.countryLivraison.closest('form').querySelector('.deliveryModeId');
                                
                                const btn = DeliveryMode.countryLivraison.closest('.delivery-livraison').querySelector('.t-deliveryMode__list-item-title');
                                DeliveryMode.checkCountry(DeliveryMode.countryFacturation, deliveryModeId, btn);
                            }
                        }
                    }
                })
            })
        }
    },
    showPrice:function(entries, totalAmount) {
        Tunnel.showPage();
        if(totalAmount) {
            DeliveryMode.totalPrice = totalAmount;
            let fees = 0;
            const deliveryModeStorage = JSON.parse(getLocalStorage('deliveryMode'));
            if(deliveryModeStorage) {
                fees = deliveryModeStorage.price;
            }
            DeliveryMode.majPrice(fees);
        }
    },
    majPrice:function(fees = 0) {
        DeliveryMode.fees = parseInt(fees);
        if(DeliveryMode.totalCartHtml) {
            let total = 0;
            if (DeliveryMode.totalPrice) {
                total = DeliveryMode.totalPrice + parseInt(fees);
            }
            DeliveryMode.totalCartHtml.forEach((elt) => {
                elt.innerHTML = priceFormatString(total)+"€";
            });
        }
    },
    showDeliveryModes: function(items) {
        // const allModes = DeliveryMode.container.querySelectorAll('.t-deliveryMode__list-item');
        const cartId = window.localStorage.getItem('cart_id');
        DeliveryMode.eltAccordeon.querySelector('.c-loader').classList.remove('show--');
        let livraisonFr = false;
        let livraisonExt = false;
        if(items && cartId) {
            items.forEach(item => {
                switch (item.id) {
                    case 1:
                        const deliveryEbillet = DeliveryMode.eltAccordeon.querySelector('.delivery-ebillet');
                        if(deliveryEbillet) {
                            deliveryEbillet.classList.add('show--');
                            deliveryEbillet.querySelector('.deliveryModeInfos').value = JSON.stringify({id:item.id, label:document.getElementById('lib-ebillet') ? document.getElementById('lib-ebillet').innerHTML : item.label, price:item.managementFeesCents});
                            deliveryEbillet.querySelector('.t-deliveryMode__list-item-title span.price').innerHTML = `+${priceFormatString(item.managementFeesCents)}€`;
                            deliveryEbillet.querySelector('button').dataset.price = item.managementFeesCents;
                            deliveryEbillet.querySelector('.cartId').value = cartId;
                        }
                      break;
                    case 4:
                        const deliveryGuichet = DeliveryMode.eltAccordeon.querySelector('.delivery-guichet');
                        if(deliveryGuichet) {
                            deliveryGuichet.querySelector('.deliveryModeInfos').value = JSON.stringify({id:item.id, label:document.getElementById('lib-guichet') ? document.getElementById('lib-guichet').innerHTML : item.label, price:item.managementFeesCents});
                            deliveryGuichet.querySelector('.t-deliveryMode__list-item-title span.price').innerHTML = `+${priceFormatString(item.managementFeesCents)}€`;
                            deliveryGuichet.classList.add('show--');
                            deliveryGuichet.querySelector('button').dataset.price = item.managementFeesCents;
                            deliveryGuichet.querySelector('.cartId').value = cartId;
                        }
                        break;
                    case 79: // livraison etranger
                        livraisonExt = true;
                        const deliveryEtranger = DeliveryMode.eltAccordeon.querySelector('.delivery-livraison');
                        if(deliveryEtranger) {
                            deliveryEtranger.classList.add('show--');
                            deliveryEtranger.querySelector('button').dataset.infoEtranger = JSON.stringify({id:item.id, label:document.getElementById('lib-livraison') ? document.getElementById('lib-livraison').innerHTML : item.label, price:item.managementFeesCents});
                            deliveryEtranger.querySelector('.cartId').value = cartId;
                            if(document.getElementsByName('billingCountryId')[0].value != 1 && document.getElementsByName('billingCountryId')[0].value!='') {
                                deliveryEtranger.querySelector('button').dataset.price = item.managementFeesCents;
                            }

                            if(deliveryEtranger.querySelector('.deliveryModeId').value == "79") {
                                deliveryEtranger.querySelector('.deliveryModeInfos').value = JSON.stringify({id:item.id, label:document.getElementById('lib-livraison') ? document.getElementById('lib-livraison').innerHTML : item.label, price:item.managementFeesCents});
                            }
                        }
                      break;
                    case 81: // livraison france
                        livraisonFr = true;
                        const deliveryFrance = DeliveryMode.eltAccordeon.querySelector('.delivery-livraison');
                        if(deliveryFrance) {
                            deliveryFrance.classList.add('show--');
                            deliveryFrance.querySelector('button').dataset.infoFrance = JSON.stringify({id:item.id, label:document.getElementById('lib-livraison') ? document.getElementById('lib-livraison').innerHTML : item.label, price:item.managementFeesCents});
                            deliveryFrance.querySelector('.t-deliveryMode__list-item-title span.price').innerHTML = `${deliveryFrance.dataset.libprice} ${priceFormatString(item.managementFeesCents)}€`;
                            deliveryFrance.querySelector('.cartId').value = cartId;
                            if(document.getElementsByName('billingCountryId')[0].value == 1 ||  document.getElementsByName('billingCountryId')[0].value == '') {
                                deliveryFrance.querySelector('button').dataset.price = item.managementFeesCents;
                            }
                            if(deliveryFrance.querySelector('.deliveryModeId').value == "81") {
                                deliveryFrance.querySelector('.deliveryModeInfos').value = JSON.stringify({id:item.id, label:document.getElementById('lib-livraison') ? document.getElementById('lib-livraison').innerHTML : item.label, price:item.managementFeesCents});
                            }
                        }
                      break;
                  }
            });
            StickyButton.makeSticky();
        }

        if(livraisonFr && !livraisonExt && DeliveryMode.countryLivraison) {//si uniquement livraison FR
            DeliveryMode.countryLivraison.value = 1;
            DeliveryMode.countryLivraison.setAttribute('readonly', 'readonly');
        }
        if(!livraisonFr && livraisonExt && DeliveryMode.countryLivraison) {//si uniquement livraison FR
            DeliveryMode.countryLivraison.remove(1);
        }
    },
    noDeliveryMode: function() {
        // alert('ici');
    },
    loadDeliveryMode: function(deliveryModeStorage, userInfo) {
        switch (deliveryModeStorage.id) {
            case 1:
                const deliveryEbillet = DeliveryMode.eltAccordeon.querySelector('.delivery-ebillet');
                DeliveryMode.stickyButtonDefault.classList.add('hide--');
                if(deliveryEbillet) {
                    deliveryEbillet.querySelector('.t-deliveryMode__list-item-title').classList.add('active--');
                    deliveryEbillet.querySelector('.t-deliveryMode__list-item-content').classList.add('active--');
                    if(userInfo) {
                        DeliveryMode.prefillForm(deliveryEbillet, userInfo);
                    }
                }
              break;
            case 4:
                const deliveryGuichet = DeliveryMode.eltAccordeon.querySelector('.delivery-guichet');
                DeliveryMode.stickyButtonDefault.classList.add('hide--');
                if(deliveryGuichet) {
                    deliveryGuichet.querySelector('.t-deliveryMode__list-item-title').classList.add('active--');
                    deliveryGuichet.querySelector('.t-deliveryMode__list-item-content').classList.add('active--');
                    if(userInfo) {
                        DeliveryMode.prefillForm(deliveryGuichet, userInfo);
                    }
                }
                break;
            case 79: // livraison etranger
                const deliveryEtranger = DeliveryMode.eltAccordeon.querySelector('.delivery-livraison');
                DeliveryMode.stickyButtonDefault.classList.add('hide--');
                if(deliveryEtranger) {
                    deliveryEtranger.querySelector('.t-deliveryMode__list-item-title').classList.add('active--');
                    deliveryEtranger.querySelector('.t-deliveryMode__list-item-content').classList.add('active--');
                    if(userInfo) {
                        DeliveryMode.prefillForm(deliveryEtranger, userInfo);
                    }
                }
              break;
            case 81: // livraison france
                const deliveryFrance = DeliveryMode.eltAccordeon.querySelector('.delivery-livraison');
                DeliveryMode.stickyButtonDefault.classList.add('hide--');
                if(deliveryFrance) {
                    deliveryFrance.querySelector('.t-deliveryMode__list-item-title').classList.add('active--');
                    deliveryFrance.querySelector('.t-deliveryMode__list-item-content').classList.add('active--');
                    if(userInfo) {
                        DeliveryMode.prefillForm(deliveryFrance, userInfo);
                    }
                }
            break;
            default:
                break;
        }
        if(deliveryModeStorage.price) {
            DeliveryMode.majPrice(parseInt(deliveryModeStorage.price));
        }
            
    },
    prefillForm: function(form, userInfo) {
        for (const item in userInfo) {
            const elt = form.querySelector('[name = '+item+']');
            if(elt && item !== "isBillingAddressSameDeliveryAddress") {
                elt.value = userInfo[item];
            }

            if(item === "email") {
                if(form.querySelector('[name = email_confirm]:not(read-only)')) {
                    form.querySelector('[name = email_confirm]:not(read-only)').value = userInfo[item];
                }
            }
            if(item === "deliveryCountryId" || item === "billingCountryId") {
                if(form.querySelector('[name = '+item+']:not([readonly]')) {
                    const options = Array.from(form.querySelector('[name = '+item+']:not([readonly])').options);
                    const optionToSelect = options.find(elt => elt.attributes.value.value == userInfo[item]);
                    if(optionToSelect) {
                        optionToSelect.selected = true;
                    }
                }
            }

            if(item === 'isBillingAddressSameDeliveryAddress') {
                if(form.querySelector('[name = '+item+']')) {
                    if(userInfo[item] === 1) {
                        form.querySelector('[name = '+item+']').checked = true;
                        form.querySelector('.t-deliveryMode__optinFacturation').classList.remove('active--');
                    }
                    else {
                        form.querySelector('[name = '+item+']').checked = false;
                        form.querySelector('.t-deliveryMode__optinFacturation').classList.add('active--');
                    }
                }
            }
        }
    }
};

export default DeliveryMode;
