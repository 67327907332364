import CountdownCart from "./CountdownCart";
const Remerciement = {
    userInfo:null,
    init: function () {
        if(document.querySelector('.t-thanks')) {
            CountdownCart.removeInfosLocal();
        }
    }
};

export default Remerciement;