'use strict';

import Form from "./Form";
import StickyButton from "./stickyButton";
import {removeLocalStorage} from "../utils/local-storage";

const Account = {
    container: null,
    logIn: null,
    guest:null,
    init: function () {
        Account.container = document.querySelector('.c-account');
        Account.logIn = document.querySelector('.olympia-logged-in');
        if(Account.container) {
            if(Account.container.querySelector('.c-account__guest')) {
                Account.checkHeight();
            }
            Account.guest = Account.container.querySelector('.btn-guest');
            Account.event();
        }
        if(Account.logIn) {//si jamais on se log ou modifie son compte on reset le localstorage des infos deja prérempli ds le tunnel
            removeLocalStorage("userInfo");//suppression du localstorage
        }
    },
    event: function() {
        if(Account.guest) {
            Account.guest.addEventListener('click', (e) => {
                e.preventDefault();
                Account.container.classList.add('open--');
                Account.checkHeight();
                StickyButton.makeSticky();
            });
        }
        if(Account.container.querySelector('.btn-close-guest')) {
            Account.container.querySelector('.btn-close-guest').addEventListener('click', (e) => {
                e.preventDefault();
                Account.container.classList.remove('open--');
                Account.checkHeight();
            });
        }
        if(document.getElementById('c-account__formGuest')) {
            if(document.getElementById('c-account__formGuest').dataset.link) {
                Form.manageForm(document.getElementById('c-account__formGuest'), document.getElementById('c-account__formGuest').dataset.link);
            }
        }
    },
    checkHeight: function() {
        if(Account.container.querySelector('.c-account__guest').offsetHeight >= Account.container.querySelector('.c-account__list'). offsetHeight) {
            if(!Account.container.classList.contains('open--')) {
                Account.container.querySelector('.c-account__list').style.height = "auto"
            }
            else {
                Account.container.querySelector('.c-account__list').style.height = Account.container.querySelector('.c-account__guest').offsetHeight+'px';
            }
        }
    }
};


export default Account;
