
'use strict';
const axios = require('axios');

import Pagination from "./Pagination";
import Card from "./Card";
import Search from "./Search";

const Prog = {
    eltResults: '',
    container:null,
    postPerPage: 9,
    getProg: function(periods, genre, page, keyword) {
        Prog.eltResults = Search.eltEventsResults.querySelector('.t-programmation__results');
        Prog.container = Search.container.querySelector('.t-search__events');
        if(Prog.eltResults) {
            const formatGenre = Prog.formatGenre(genre);
            const formatPeriods = Prog.formatPeriods(periods);
            axios({
                method: "get",
                url: `${Search.urlSearchEvents}${formatPeriods}${formatGenre}&page=${page}&posts_per_page=${Prog.postPerPage}&keyword=${keyword}`,
            }).then(function (response) {
                    //handle success
                    if (response.status === 200) {
                        Prog.showProg(response.data);
                        
                        if(window.matchMedia("(max-width: 768px)").matches) {
                            Pagination.getPagesMobile(response.data.nb_pages, page);
                        }
                        else {
                            Pagination.getPages(response.data.nb_pages, page);
                        }
                    } else {
                        Prog.eltResults.innerHTML = 'Error';
                        Search.hideLoader();
                        console.log('errors', response.statusText);

                    }
                }
            ).catch(function (response) {
                //handle error
                console.log('err', response);
                Prog.eltResults.innerHTML = '<p class="noresult">Error</p>';
                Search.hideLoader();
            });
        }
    },
    showProg: function(data) {
        let html = '';
        if(data.total>0) {
            data.items.forEach((item) => {
                html+=`
                <div class="c-card event--" data-link="${item.permalink}">
                    <div class="c-card__img">
                        <div class="c-card__img-ctn">;`;
                            if(item.meta.image.sizes["page-programmation-desktop"] && item.meta.image.sizes["page-programmation-desktopX2"]) {
                                html+=`
                                <picture>
                                    <img loading="lazy" src="${item.meta.image.sizes["page-programmation-desktopX2"]}" alt="${item.meta.image.alt}" width="${item.meta.image.sizes["page-programmation-desktopX2-width"]}" height="${item.meta.image.sizes["page-programmation-desktopX2-height"]}" />
                                </picture>`;
                            }
                        
                        html+=`</div>
                        <div class="c-button__ctn">
                            <div class="c-button border--" title="${item.meta.cta.title}"><span>${item.meta.cta.title}</span><i class="icon-${item.meta.cta.icon}"></i></div>
                        </div>
                    </div>
                    <a href="${item.permalink}" class="c-card__content" title="${Search.eltEventsResults.dataset.reserve}">
                        <h2 class="h5 c-card__title">${item.post_title}</h2>
                        <div class="c-card__suptitle">${item.meta.period_date}</div>`;
                        
                        if(item.meta.infos_text_status) {
                            html+=`<p class="c-card__status">${item.meta.infos_text_status}</p>`;
                        }
                        html+=`
                    </a>
                </div>`;
            })
            if (data.total) {
                Search.totalResults = Search.totalResults + data.total;
            }
            if(Prog.container) {
                Prog.container.classList.remove('hide--');
            }
        }
        else {
            //No results
            if(Prog.container) {
                Prog.container.classList.add('hide--');
            }
            html+=`<p class="noresult">${Search.eltEventsResults.dataset.noresults}</p>`;
        }
        Search.hideLoader();
        Prog.eltResults.innerHTML = html;
        Card.init();
    },
    formatGenre: function(genre) {
        let returnGenre =''
        if(genre!='' && genre.length>0) {
            genre.forEach(elt => {
                returnGenre+="&filter_genre[]="+elt;
            });
        }
        return returnGenre;
    },
    formatPeriods: function(periods) {
        let returnPeriods =''
        if(periods && periods.length > 0) {
            periods.forEach((elt, key) => {
                if(elt.periodStart) {
                    returnPeriods+=`&filter_periods[${key}][begin_date]=${elt.periodStart}`
                }
                if(elt.periodEnd) {
                    returnPeriods+=`&filter_periods[${key}][end_date]=${elt.periodEnd}`
                }
            })
        }
        return returnPeriods;
    }
};

export default Prog;