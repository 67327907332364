import {getLocalStorage, removeLocalStorage, saveLocalStorage} from "./local-storage";
import CountdownCart from "../components/CountdownCart";
import Plan from "../components/Plan";
import CartSummary from "../components/CartSummary";
import Header from "../components/Header";

const axios = require("axios");

export function generateErrorBanner(id, message) {
    const container = document.querySelector('.stickyContainer--');
    if (container) {
        const error = `<div class="c-stickyError show--" id="${id}">
                    <div class="error global-- active" aria-live="polite">${message}
                        <span></span>
                    </div>
                </div>`;
        container.insertAdjacentHTML("afterbegin", error);
    }
}

export function removeErrorBanner(id) {
    const error = document.querySelector('#' + id);
    if (error) {
        error.remove();
    }
}


export const initCart = () => {
    // console.log("call initCart");
    const request = () => {
        // console.log("request initCart");
        const url = window.location.origin + '/wp-json/' + process.env.MIX_INIT_CART;

        axios({
            method: "get",
            url: url,
        }).then(function (response) {
            const {data: {datas: {cartId, token_cart}, success, message}} = response;
            if (success && cartId && token_cart) {
                //handle success
                saveLocalStorage('cart_id', cartId);
                saveLocalStorage('token_cart', token_cart);
                // Get cart infos (timer expiration, selectedPlaces)
                getCartInfos();
            } else {
                //handle error
                console.log('err initCart', message);
                generateErrorBanner('initCart-err', 'Une erreur est survenue lors de la création du panier, veuillez réessayer');
            }
        }).catch(function (response) {
            //handle error
            console.log('err catch initCart', response);
            generateErrorBanner('initCart-err-catch', 'Une erreur est survenue lors de la création du panier, veuillez réessayer');
        });
    };

    if (Plan.showId) {
        if (getLocalStorage('cart_id') && getLocalStorage('token_cart')) {
            CountdownCart.checkTimer(() => {
                    console.log('cart already initialized and expiration_date < current_date');
                    getCartInfos();
                },
                () => request(),
                () => request()
            );
        } else {
            request();
        }
    } else {
        generateErrorBanner('initCart', 'Veuillez sélectionner un événement');
    }

};

/**
 * @param {function} successCallback callback function if success
 */
export const getCartInfos = (successCallback) => {
    const request = () => {
        const cartId = getLocalStorage('cart_id');
        const token_cart = getLocalStorage('token_cart');
        if (cartId && token_cart) {
            const lang = document.documentElement.lang.substr(0, 2);

            const url = `${window.location.origin}/wp-json/${process.env.MIX_GET_CART_INFOS}${cartId}/token/${token_cart}?lang=${lang}`;

            axios({
                method: "get",
                url: url,
            }).then(function (response) {
                console.log('getCartInfos', response);
                const {data: {datas: success, message}} = response;
                if (success) {
                    //handle success
                    const {data: {datas: {expirationDate, totalAmount, entries}}} = response;
                    if (expirationDate) {
                        saveLocalStorage('cart_expiration_date', expirationDate);
                        CountdownCart.checkCart();
                    }
                    // set selectedPlaces : entries
                    // console.log('getCartInfos entries : ', entries === undefined ? 'Empty cart' : entries);
                    if (document.querySelector('.c-plan')) {
                        if (entries && entries.length > 0) {
                            Plan.setSelectedPlacesOnLoad(entries);
                        } else {
                            Plan.setSelectedPlacesOnLoad([]);
                        }
                    }
                    if (entries && entries.length > 0) {
                        saveLocalStorage('cart_nb_entry', entries.length);
                        const interval = setInterval(() => {
                            if (window._paq) {
                                let amount = 0;
                                entries.forEach(entry => {
                                    // Matomo ecommerce

                                    // console.log('addEcommerceItem',
                                    //     entry.eventId + '-' + entry.showId + '-' + entry.entryId,
                                    //     entry.eventName,
                                    //     entry.priceName,
                                    //     entry.priceValueEuros.replace(',', '.'),
                                    //     1);

                                    // An addEcommerceItem push should be generated for each cart item, even the products not updated by the current "Add to cart" click.
                                    window._paq.push(['addEcommerceItem',
                                        entry.eventId + '-' + entry.showId + '-' + entry.entryId,  // (Required) productSKU
                                        entry.eventName, // (Optional) productName
                                        entry.priceName, // (Optional) productCategory
                                        entry.priceValueEuros.replace(',', '.'), // (Recommended) price
                                        1 // (Optional, defaults to 1) quantity
                                    ]);

                                    amount += parseFloat(entry.priceValueEuros.replace(',', '.'));
                                });

                                // Pass the Cart's Total Value as a numeric parameter
                                //console.log('trackEcommerceCartUpdate', amount);
                                window._paq.push(['trackEcommerceCartUpdate', amount]);

                                clearInterval(interval);
                            }
                        }, 200);

                    } else {
                        removeLocalStorage('cart_nb_entry')
                    }
                    if (successCallback) {
                        successCallback(entries, totalAmount);
                    }
                    Header.checkButtonCart(entries);
                } else {
                    //handle error
                    console.log('err getCartInfos : ' + message);
                    if (document.querySelector('.t-tunnel.loading--')) {
                        document.querySelector('.t-tunnel.loading--').classList.remove('loading--');
                    }
                    if (document.querySelector('.c-cartSummary')) {
                        document.querySelector('.c-cartSummary').innerHTML = "";
                    }
                    generateErrorBanner('cartInfos-err-catch', 'Une erreur est survenue lors de la récupération du panier, veuillez réessayer');
                    CountdownCart.removeInfosLocal();
                }

            }).catch(function (response) {
                //handle error
                console.log('err catch getCartInfos', response);
                generateErrorBanner('cartInfos-err-catch', 'Une erreur est survenue lors de la récupération du panier, veuillez réessayer');

            });
        } else {
            console.log('err getCartInfos : missing/wrong cartId|token');
            if (document.querySelector('.c-plan')) {
                if (Plan.showId) {
                    initCart(Plan.showId);
                } else {
                    generateErrorBanner('cartInfos', 'Veuillez sélectionner un événement');
                }
            } else {
                if (document.querySelector('.c-cartSummary')) {
                    CartSummary.showCartInfos();
                }
            }
        }
    }

    request();
};

/**
 * @param {integer} showId example 9136383
 * @param {object} item  example const item = {
 *                     location: placeLocation,
 *                     row: placeRow,
 *                     number: placeNumber,
 *                     id: placeID,
 *                     strapontin: strapontin,
 *                     price:  seat.valueEuro,
 *                     priceId: seat.id,
 *                     cat: {slug: slugify(cat.priceCategory), name: cat.name},
 *                     fosse: false
 *                 };
 * @param {function} successCallback callback function if success
 * @param {function} errCallback callback function if error
 */

export const addToCart = (showId, item = false, successCallback = null, errCallback) => {
    if (showId && item) {
        const request = () => {
            // Check localStorage
            const cartId = getLocalStorage('cart_id');
            const token_cart = getLocalStorage('token_cart');
            // If item in localStorage
            if (cartId && token_cart) {
                const url = window.location.origin + '/wp-json/' + process.env.MIX_ADD_TO_CART + cartId + /token/ + token_cart;

                let paramPromoCode = '';
                const needsPromoCode = Plan.categoriesArray.find(cat => cat.id === parseInt(item.priceId)).hasCodesPromo;
                if (needsPromoCode) {
                    const accessCodeJSON = getLocalStorage('access_code');
                    const accessCode = JSON.parse(accessCodeJSON)
                    if (accessCode && accessCode.code !== "" && accessCode.code !== null && accessCode.showId === showId) {
                        paramPromoCode = `&accessCode=${accessCode.code}`;
                    }
                }

                let requestUrl = `${url}?showId=${showId}&priceId=${item.priceId}&seatsIds[]=${item.seeticketsId}${paramPromoCode}`;
                if (item.fosse) {
                    requestUrl = `${url}?showId=${showId}&priceId=${item.priceId}&forceRandomSeatForSection=orchestre${paramPromoCode}`;
                }
                axios({
                    method: "post",
                    url: requestUrl,
                }).then(function (response) {
                    const {data: {datas, success, message}} = response;
                    if (success) {
                        //handle success
                        // console.log('TAG Matomo add to cart')
                        getCartInfos(Plan.addToCartTagMatomo);

                        /*  if (successCallback) {
                              successCallback(item);
                          }*/
                    } else {
                        //handle error
                        console.log('err addToCart : ' + message);
                        generateErrorBanner('add-' + item.seeticketsId, "Une erreur est survenue lors de l'ajout au panier : " + message);
                        setTimeout(() => removeErrorBanner('add-' + item.seeticketsId), 5000);
                        if (errCallback) {
                            errCallback(datas);
                        }
                    }

                }).catch(function (response) {
                    //handle error
                    console.log('err catch addToCart', response);
                    generateErrorBanner('add-' + item.seeticketsId + '-catch', "Une erreur est survenue lors de l'ajout au panier : " + response);
                    setTimeout(() => removeErrorBanner('add-' + item.seeticketsId + '-catch'), 5000);
                });
            }
        };

        CountdownCart.checkTimer(() => request(),
            () => initCart(),
            () => initCart()
        );
    } else {
        console.log('err addToCart : missing params (showID|item)');
        generateErrorBanner('add', "Une erreur est survenue lors de l'ajout au panier, veuillez réessayer");
    }
};

/**
 * @param {integer} showId example 9136383
 * @param {object} item  example const item = {
 *                     location: placeLocation,
 *                     row: placeRow,
 *                     number: placeNumber,
 *                     id: placeID,
 *                     strapontin: strapontin,
 *                     price:  seat.valueEuro,
 *                     priceId: seat.id,
 *                     cat: {slug: slugify(cat.priceCategory), name: cat.name},
 *                     fosse: false
 *                 };
 * @param {function} successCallback callback function if success
 * @param {function} errCallback callback function if error
 */

export const deleteFromCart = (showId, item, successCallback, errCallback) => {
    if (showId && item) {
        const request = () => {
            // Check localStorage
            const cartId = getLocalStorage('cart_id');
            const token_cart = getLocalStorage('token_cart');
            // If item in localStorage
            if (cartId && token_cart) {
                const url = window.location.origin + '/wp-json/' + process.env.MIX_DELETE_FROM_CART + cartId + '/entry/' + item.entryId + '/token/' + token_cart;

                axios({
                    method: "post",
                    url: url,
                }).then(function (response) {
                    const {data: {success, message}} = response;
                    if (success) {
                        //handle success
                        if (successCallback) {
                            successCallback(item);
                            saveLocalStorage("cart_nb_entry", getLocalStorage("cart_nb_entry") - 1);//suppression du localstorage
                        }
                    } else {
                        //handle error
                        console.log('err deleteFromCart', message);
                        generateErrorBanner('delete-' + item.entryId, "Une erreur est survenue lors de la suppression de cette place du panier : " + message);
                        if (errCallback) {
                            errCallback(message);
                        }
                    }
                }).catch(function (response) {
                    //handle error
                    console.log('err catch deleteFromCart', response);
                    generateErrorBanner('delete-' + item.entryId + '-catch', "Une erreur est survenue lors de la suppression de cette place du panier : " + response);
                });
            }
        };

        CountdownCart.checkTimer(() => request(),
            () => initCart(),
            () => initCart()
        );

    } else {
        console.log('err deleteFromCart : missing params (showID|item)');
        generateErrorBanner('delete', "Une erreur est survenue lors de la suppression de cette place du panier, veuillez réessayer");
    }
};

/**
 * @param {integer} eventId example 123
 * @param {integer} showId example 9136383
 * @param {function} successCallback callback function if success
 * @param {function} errCallback callback function if error
 */

export const getSeatsPlan = (eventId, showId, successCallback, errCallback) => {
    if (showId && eventId) {
        let url;
        const lang = document.documentElement.lang.substr(0, 2);
        const preventeJSON = getLocalStorage('codePrevente');
        const prevente = JSON.parse(preventeJSON);
        if (prevente && prevente.eventId == eventId) {
            if (prevente.hash != "" && prevente.hash != null) {
                url = `${window.location.origin}/wp-json/${process.env.MIX_GET_SEATS_PLAN}${eventId}/${showId}/?mdp=${prevente.hash}&lang=${lang}`;
            } else {
                console.log('err getSeatsPlan prevente : ' + 'Cet événement nécessite un mot de passe pour accéder aux préventes');
                generateErrorBanner('getSeatsPlan-err-prevente', 'Cet événement nécessite un mot de passe pour accéder aux préventes');
                if (errCallback) {
                    errCallback();
                }
            }
        } else {
            url = `${window.location.origin}/wp-json/${process.env.MIX_GET_SEATS_PLAN}${eventId}/${showId}/?lang=${lang}`;
        }

        if (url) {
            axios({
                method: "get",
                url: url,
            }).then(function (response) {
                const {data: {datas, success, message}} = response;
                if (success) {
                    //handle success
                    if (successCallback) {
                        successCallback(datas);
                    }
                } else {
                    //handle error
                    console.log('err getSeatsPlan : ' + message);
                    generateErrorBanner('getSeatsPlan-err', message);
                    if (errCallback) {
                        errCallback();
                    }
                }
            }).catch(function (response) {
                //handle error
                console.log('err catch getSeatsPlan', response);
                generateErrorBanner('getSeatsPlan-err-catch', 'Une erreur est survenue lors de la récupération du plan de salle, veuillez réessayer');
            });
        }

    } else {
        console.log('err getSeatsPlan : missing showId|eventId');
        generateErrorBanner('getSeatsPlan', 'Veuillez sélectionner un événement');
    }
}

/**
 */
export const getDeliveryMode = (successCallback, errCallback) => {
    // Check localStorage
    const cartId = getLocalStorage('cart_id');
    const token_cart = getLocalStorage('token_cart');
    if (cartId && token_cart) {
        const url = window.location.origin + '/wp-json/' + process.env.MIX_GET_DELIVERY_MODES + cartId + '/token/' + token_cart;

        axios({
            method: "post",
            url: url,
        }).then(function (response) {
            const {data: {success, message}} = response;
            // console.log(response)
            if (success) {
                //handle success
                if (successCallback) {
                    successCallback(response.data.datas.deliveryModes);
                }
            } else {
                //handle error
                console.log('err getDeliveryModes', message);
                if (errCallback) {
                    errCallback(message);
                }
            }
        }).catch(function (response) {
            //handle error
            console.log('err getDeliveryModes', response);
        });
    }
};


/**
 * @param {function} successCallback callback function if success
 * @param {function} errCallback callback function if error
 */

export const deleteAllCart = (successCallback, errCallback) => {
    // Check localStorage
    const cartId = getLocalStorage('cart_id');
    const token_cart = getLocalStorage('token_cart');
    // If item in localStorage
    if (cartId && token_cart) {
        const url = window.location.origin + '/wp-json/' + process.env.MIX_DELETE_ALL_CART + cartId + '/token/' + token_cart;

        axios({
            method: "post",
            url: url,
        }).then(function (response) {
            const {data: {success, message}} = response;
            if (success) {
                //handle success
                if (successCallback) {
                    //tag matomo
                    if (window._mtm) {
                        window._mtm = window._mtm || [];
                        window._mtm.push({ecommerce: null});  // Clear the previous ecommerce object.
                        window._mtm.push({
                            event: "update_cart",
                            ecommerce: {
                                value: "0",
                                items: []
                            }
                        });
                    }
                    successCallback();
                }
            } else {
                //handle error
                console.log('err deleteAllCart', message);
                generateErrorBanner('delete-all-cart', "Une erreur est survenue lors de la suppression du panier : " + message);
                if (errCallback) {
                    errCallback(message);
                }
            }
        }).catch(function (response) {
            //handle error
            console.log('err catch deleteAllCart', response);
            generateErrorBanner('delete-all-cart-catch', "Une erreur est survenue lors de la suppression du panier : " + response);
        });
    }
};
