'use strict';

// core version + modules:
import Swiper
    from 'swiper';

// Import Swiper styles
import 'swiper/css';

const News = {
    els: null,
    init: function () {
        News.els = document.querySelectorAll('.f-news');
        if (News.els && News.els.length > 0) {
            News.els.forEach(el => {
                const slides = el.querySelectorAll('.swiper-slide');
                News.create(el, slides.length);
            });
        }
    },
    create: function (el, slidesLength) {
        const slider = el.querySelector('.swiper');
        const sliderWrapper = slider.querySelector('.swiper-wrapper');

       // let noCursor = false;
        // if(window.matchMedia('(min-width:540px)').matches && slidesLength > 1){
        //     console.log('min-width:540px && slidesLength > 1');
        // }

        // if(!(window.matchMedia('(min-width:992px)').matches && slidesLength > 3)){
        //     console.log(window.matchMedia('(min-width:992px)').matches && slidesLength > 3);
        // }
        let noCursor = !(window.matchMedia('(min-width:992px)').matches && slidesLength > 3);

        if (noCursor === true) {
            sliderWrapper.classList.add('noDragCursor');
        } else {
            if (sliderWrapper.classList.contains('noDragCursor')) {
                sliderWrapper.classList.remove('noDragCursor');
            }
        }

        let swiper = new Swiper(slider, {
            touchStartPreventDefault: false,
            spaceBetween: 0,
            slidesPerView: 1.15,
            loop: false,
            speed: 600,
            grabCursor: false,
            autoHeight: false,
            breakpoints: {
                // when window width is >= 540px
                540: {
                    slidesPerView: 1.6,
                },
                // when window width is >= 768px
                768: {
                    slidesPerView: 2.4,
                },
                // when window width is >= 992px
                992: {
                    slidesPerView: 3.2,
                },
                // when window width is >= 1400px
                1400: {
                    slidesPerView: 3.6,
                }
            }
        });
    },
};

export default News;