'use strict';

import Popin from "./Popin";

const StickyEvent = {
    container:'',
    select:'',
    showIdSelected:'',
    init: function () {
        if(document.querySelector('.c-stickyEvent')) {
            StickyEvent.container = document.querySelector('.c-stickyEvent');
            StickyEvent.select = StickyEvent.container.querySelector('.c-stickyEvent__select');
            StickyEvent.showIdSelected = document.getElementById('showIdSelected');
            StickyEvent.initSelect();
            StickyEvent.scrollAffiche();
            StickyEvent.event();
            if (window.matchMedia('(min-width:768px)').matches) {
                StickyEvent.scrollSticky();
            }
            if(StickyEvent.container && document.querySelector('.f-sticky')){
                StickyEvent.container.style.bottom=document.querySelector('.f-sticky').offsetHeight+"px";
            }
        }
    },
    initSelect: function() {
        if (document.querySelector('.c-stickyEvent__select')) {
            let el = document.querySelector('.c-stickyEvent__select');
            let options = el.querySelectorAll('.c-stickyEvent__options-list li');

            // Open dropdown panel
            el.addEventListener('click', () => {
                el.classList.add('show-options');
                setTimeout(function () {
                    el.classList.add('anim-options');
                }, 50);
            });
            if(document.querySelector('.c-stickyEvent__select .selected--'))
                StickyEvent.showIdSelected = document.querySelector('.c-stickyEvent__select .selected--').dataset.idshow;


            // Close dropdown panel
            if (options) {
                options.forEach(option => {
                    option.addEventListener('click', (e) => {
                        e.stopPropagation();
                        StickyEvent.closeSelect(StickyEvent.select);//fermeture du select si ouvert
                        StickyEvent.select.querySelector('.c-stickyEvent__current span').innerHTML = option.dataset.label;
                        StickyEvent.showIdSelected = option.dataset.idshow;
                        StickyEvent.select.querySelector('.selected--')?.classList.remove('selected--');
                        option.classList.add('selected--');
                    });
                });
            }


            // Clicked outside dropdown panel
            window.addEventListener('click', function (event) {
                const target = event.target || event.srcElement || event.currentTarget;
                if (!el.contains(target) && el.classList.contains('show-options')) {
                    StickyEvent.closeSelect(el);
                }
            });
        }
    },
    closeSelect: function (elt) {
        setTimeout(function () {
            elt.classList.remove('anim-options');
            elt.classList.remove('show-options');
        }, 200);
    },
    event: function() {
        if(StickyEvent.container.querySelector('.c-stickyEvent__current')) {
            StickyEvent.container.querySelector('.c-stickyEvent__current').addEventListener('click', (e) => {
                if(StickyEvent.container.querySelector('.c-stickyEvent__select').classList.contains('show-options')) {
                    StickyEvent.closeSelect(StickyEvent.select);
                }
            });
        }
        if(StickyEvent.container.querySelector('.c-stickyEvent__form')) {
            StickyEvent.container.querySelector('.c-stickyEvent__form').addEventListener('submit', (e) => {
                e.preventDefault();
                const form = e.target;
                if(document.getElementById('popinPrevente').value == 1) {
                    //Affichage popin de prevente

                    const codePrevente = JSON.parse(window.localStorage.getItem('codePrevente'));
                    if(codePrevente && codePrevente.eventId === document.getElementById('eventId').value && codePrevente.hash!="") {
                        window.location.href = e.target.dataset.url+document.getElementById('slugEventId').value+'/'+StickyEvent.showIdSelected;
                    }
                    else {
                        Popin.showPopin(document.getElementById('stickyEvent'));
                        document.getElementById('showId').value = StickyEvent.showIdSelected;
                    }
                }
                else {
                    if(e.target.dataset.url && e.target.dataset.url!='' && StickyEvent.showIdSelected !="" && document.getElementById('eventId').value !="")
                        window.location.href = e.target.dataset.url+document.getElementById('slugEventId').value+'/'+StickyEvent.showIdSelected;
                }
            })
        }
    },
    scrollAffiche: function() {
        window.addEventListener('scroll', (e) => {
            var st = window.pageYOffset || document.documentElement.scrollTop;
            if(st>0) {
                StickyEvent.container.classList.add('down--');
            }
            else {
                StickyEvent.container.classList.remove('down--');
            }
        });
    },
    scrollSticky: function() {
        window.addEventListener('scroll', (e) => {
            let bloc = document.querySelector('.c-headerEvent__content');
            // console.log(bloc)
            let bounding = bloc.getBoundingClientRect();
            // console.log(bounding.bottom)
            if ( bounding.bottom <= 0) {
                StickyEvent.container.classList.add('sticky--');
            } else {
                // Retirer la classe "sticky" si le bloc n'est pas entièrement visible
                StickyEvent.container.classList.remove('sticky--');
            }
        });
    }
};

export default StickyEvent;