'use strict';
const axios = require('axios');
import {getDateByMonth, firstLastDayOfWeek, firstLastDayOfMonth, getTodayDate} from "../utils/date";
import Filter from "./Filter";
import Calendar from "./Calendar";
import Prog from "./SearchProgrammation";
import Pages from "./SearchPages";
import Pagination from "./Pagination";
import Popin from "./Popin";


const Search = {
    container: null,
    url: null,
    urlSearchEvents: null,
    urlSearchPages: null,
    urlCalendar: null,
    eltEventsResults: null,
    eltPagesResults: null,
    eltCalendar: null,
    totalResults: 0,
    eltTotalResults:null,
    init: function () {
        //Recuperation des url d'api
        const lang = document.documentElement.lang.substr(0, 2);
        if (lang && process.env.MIX_SEARCH_EVENTS) {
            Search.urlSearchEvents = window.location.origin + '/wp-json/' + process.env.MIX_SEARCH_EVENTS+'?lang='+lang;
        }
        if (lang && process.env.MIX_SEARCH_PAGES) {
            Search.urlSearchPages = window.location.origin + '/wp-json/' + process.env.MIX_SEARCH_PAGES+'?lang='+lang;
        }
        if (lang && process.env.MIX_CALENDAR) {
            Search.urlCalendar = window.location.origin + '/wp-json/' + process.env.MIX_CALENDAR+'?lang='+lang;
        }

        Search.container = document.querySelector('.t-programmation');
        if(Search.container && Search.urlSearchEvents && Search.urlSearchPages && Search.urlCalendar) {
            Search.eltEventsResults = Search.container.querySelector('.t-programmation__resultsContent');
            Search.eltPagesResults = Search.container.querySelector('.t-search__resultsContent');
            Search.eltCalendar = Search.container.querySelector('.c-calendar');
            Search.eltTotalResults = Search.container.querySelector('.c-search__nbResults');
            Filter.init();
            Search.getParams();
            Pagination.init();
        }

        if(document.querySelector('.c-search .c-search__form')) {
            document.querySelector('.c-search .c-search__form').addEventListener('submit', (e) => {
                e.preventDefault();
                Search.removeParams("page_");
                if(e.target.closest('.c-popin')) {
                    Filter.submitSearchPopin();//On submit ds la popin
                }
                else {//On submit ds la page
                    Filter.closeFilter();
                    Filter.submitSearch();
                }
            })
        }
        if(document.getElementById('btn-search')) {
            document.getElementById('btn-search').addEventListener('click', function() {
                document.getElementById('btn-search').classList.remove('show--');
            });
        }
    },
    getParams:function() { //Récupération des params ds l'url
        document.querySelector("link[rel='canonical']").href=document.location;
        console.log(document.location)
        //params url :
        //       date_=    filtre par date
        //       genre_=   filtre par genre
        //       month_=   filtre par mois/annee
        //       year_=    filtre par mois/annee
        //       page_=    page affichée
        //       keyword_= mot recherché
        //       periods_= filtre par periode
        Search.totalResults = 0;
        Search.showLoader();
        let params = (new URL(document.location)).searchParams;
        let periods = [], genre = [], page = 1, keyword = '';
        if(params.get('page_')) { //s'il y a une pagination
            if(params.get('page_') > 0) {
                page = params.get('page_');
            }
            else {
                Search.removeParams("page_");
            }
        }

        if(params.get('keyword_')) { //s'il y a un mot clé
            keyword = params.get('keyword_');
            document.getElementById('search_text').value = keyword;
            if(document.getElementById('btn-search')) {
                document.getElementById('btn-search').classList.add('show--');
            }
        }

        
        // if(params.get('genre_') || params.get('periods_')) {//s'il y a des filtres (genre ou periode)
        if(params.get('genre_')) {//s'il y a des genres
            genre = params.get('genre_').split('_');
        }

        let periodsFilter ='';
        if(params.get('periods_')) { //s'il y a une period
            periodsFilter = params.get('periods_').split('_');
            periodsFilter.forEach((elt) => {
                if(elt==="week") {
                    const dateWeeks = firstLastDayOfWeek();
                    periods.push({"periodStart" : dateWeeks.begin, "periodEnd" : dateWeeks.end});
                }
                else if(elt==="month") {
                    const dateMonth = firstLastDayOfMonth();
                    periods.push({"periodStart" : dateMonth.begin, "periodEnd" : dateMonth.end});
                }
                else if(elt.indexOf('year-')>=0) {
                    const year = elt.split('year-')[1];
                    periods.push({"periodStart" : `${year}-01-01`, "periodEnd" : `${year}-12-31`});
                }
                else if(elt.indexOf('month-')>=0) {
                    const month = elt.split('month-')[1];
                    const dateMonth = firstLastDayOfMonth(month);
                    periods.push({"periodStart" : dateMonth.begin, "periodEnd" : dateMonth.end});
                } 
            });
        }
        Filter.activeFilter(genre, periodsFilter, true);
        // }
        // else {
        //     Filter.filterList.innerHTML = '';
        // }
        if(Search.eltCalendar) {
            if(params.get('date_')) {//sil y a une date dans l'url
                Calendar.filterDate = params.get('date_');
                Calendar.getDays(String(new Date(params.get('date_')).getMonth()+1).padStart(2, '0'), new Date(params.get('date_')).getFullYear());
                periods.push({"periodStart" : params.get('date_'), "periodEnd" : params.get('date_')});
            }
            else if(params.get('month_') && params.get('year_')) {
                Calendar.getDays(params.get('month_'), params.get('year_'));
                //obtenir la date de début
                periods.push({"periodStart" : getDateByMonth(params.get('month_'), params.get('year_'))});
            }
            else {//par défaut on affiche le mois courant
                Calendar.getDays(String(new Date().getMonth()+1).padStart(2, '0'), new Date().getFullYear());
                periods.push({"periodStart" : getTodayDate()});
            }
        }
        else {
            if(!params.get('periods_')) {
                periods.push({"periodStart": getTodayDate()});
            }
        }
        Prog.getProg(periods, genre, page, keyword);
        if(Search.eltPagesResults) {
            Pages.getPages(page, keyword);
        }
        
    },
    updateParams:function(params, value) {
        console.log(value);
        let newValue = value;
        if(Array.isArray(value)){
            newValue = value.join('_');
        }
        console.log(newValue);
        let url = new URL(window.location);
        url.searchParams.set(params, newValue);
        url.search = decodeURIComponent(url.search);
        window.history.pushState({}, '', url);
    },
    removeParams:function(params) {
        var url = new URL(window.location);
        url.searchParams.delete(params);
        window.history.pushState({}, '', url);
    },
    showLoader:function() {
        Search.eltEventsResults.querySelector('.t-programmation__results').innerHTML='';
        if(Search.eltPagesResults) {
            Search.eltPagesResults.querySelector('.t-search__results').innerHTML='';
        }
        if(Pagination.elt) {
            Pagination.elt.querySelector('.c-pagination__list').innerHTML = '';
            Pagination.elt.classList.remove('show--');
        }
        Search.container.classList.add('loading--')
        const timer = setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });;
            clearTimeout(timer)
        }, 100);
        
    },

    hideLoader:function() {
        Search.container.classList.remove('loading--');
        if(Search.eltTotalResults) {
            Search.eltTotalResults.innerHTML = `<p>${Search.totalResults} ${Search.totalResults > 1 ? Search.eltTotalResults.dataset.libellepluriel : Search.eltTotalResults.dataset.libellesingulier}</p>`
        }
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // });
    },

    getPages: function(page, keyword) {
        axios({
            method: "get",
            url: `${Search.url}&page=${page}&posts_per_page=${Search.postPerPage}&keyword=${keyword}`,
        }).then(function (response) {
                //handle success
                if (response.status === 200) {
                    Prog.showProg(response.data);
                    if(window.matchMedia("(max-width: 768px)").matches) {
                        Pagination.getPagesMobile(response.data.nb_pages, page);
                    }
                    else {
                        Pagination.getPages(response.data.nb_pages, page);
                    }
                } else {
                    Prog.eltResults.innerHTML = 'Error';
                    Prog.hideLoader();
                    console.log('errors', response.statusText);
                }
            }
        ).catch(function (response) {
            //handle error
            console.log('err', response);
            Prog.eltResults.innerHTML = '<p class="noresult">Error</p>';
            Prog.hideLoader();
        });
    },
    header: function(url) {
        if(document.querySelector('.page-template-page-search')) { //on est sur la page de recherche
            if(document.getElementById('search_text')) {
                document.getElementById('search_text').focus();
            }
        }
        else {
            if(!Search.eltEventsResults) { //on est sur la page agenda
                Filter.init();
            }
            Filter.url = url;
            Popin.showPopin(document.getElementById('searchForm'));
        }
    }
};

export default Search;
