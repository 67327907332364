'use strict';

const Languages = {
    els: null,
    init: function () {
        Languages.els = document.querySelectorAll('.c-languages');
        if (Languages.els) {

            Languages.els.forEach(el => {
                let currentLang = el.querySelector('.c-languages__current');
                let options = el.querySelectorAll('.c-languages__options-list li');

                // Open language dropdown panel
                el.addEventListener('click', () => {
                    el.classList.add('show-options');
                    setTimeout(function () {
                        el.classList.add('anim-options');
                    }, 50);

                    setTimeout(function () {
                        el.classList.add('show-shadow');
                    }, 200);
                });

                // Close language dropdown panel
                if (options) {
                    options.forEach(option => {
                        option.addEventListener('click', (e) => {
                            e.stopPropagation();
                            Languages.close(el);
                            currentLang.querySelector('span').innerHTML = option.dataset.lang;
                            option.classList.add('selected');
                            el.querySelector('.selected')?.classList.remove('selected');
                        });

                    });
                }


                // Clicked outside dropdown panel
                window.addEventListener('click', function (event) {
                    const target = event.target || event.srcElement || event.currentTarget;
                    if (!el.contains(target) && el.classList.contains('show-options')) {
                        Languages.close(el);
                    }
                });

            });
        }
    },
    close: function (el) {
        el.classList.remove('anim-options');
        el.classList.remove('show-shadow');
        setTimeout(function () {
            el.classList.remove('show-options');
        }, 600);
    }
};

export default Languages;
