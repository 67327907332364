'use strict';

const Modal =  {
    buttons:null,
    init: function(){
       Modal.buttons = document.querySelectorAll('[data-modal]');
       if(Modal.buttons && Modal.buttons.length>0){
           Modal.buttons.forEach(button =>{
               button.addEventListener('click', () => {
                   const targetModal = button.dataset.modal;

                   const modal = document.querySelector('.c-modal#'+targetModal);
                   if(modal){
                       // Open modal
                       Modal.open(modal);


                       // Close modal
                       const closeButton =  modal.querySelector('.c-modal__close');
                       if(closeButton){
                           closeButton.addEventListener('click', () => {
                              Modal.close(modal);
                           });
                       }
                       // Click outside modal
                       const backdrop = modal.querySelector('.c-modal__backdrop');
                       if(backdrop) {
                           backdrop.addEventListener('click', () => {
                               Modal.close(modal);
                           });
                       }
                   }
               });
           });
       }
    },
    open: function(modal){
        modal.classList.add('active');

        if (window.matchMedia('(min-width:1025px)').matches) {
            const cursorWrapper = document.querySelector('.cursor__wrapper:not(.header--)');
            // cursorWrapper.remove();
            const modalContainer = modal.querySelector('.c-modal__ctn >.container');
            if(modalContainer)
                modalContainer.insertAdjacentElement('afterbegin', cursorWrapper);
        }
    },
    close: function(modal, resetForm=true){
        const app = document.querySelector('.app');

        function updateCursor(){
            if (window.matchMedia('(min-width:1025px)').matches) {
                const cursorWrapper = document.querySelector('.cursor__wrapper:not(.header--)');
                app.insertAdjacentElement('beforeend', cursorWrapper);
            }
        }

        if(resetForm){
            const form = modal.querySelector('form');
            if(form){
                form.reset();
            }
        }

        modal.classList.remove('active');
        updateCursor();
    }
};

export default Modal;
