'use strict';

const Background = {
    init: function () {
        var bg = document.querySelector(".bgGlobal");

        if (bg) {
            const PATH = "/wp-content/themes/olympia/dist/assets/img/";
            Background.CYCLE = 10000;

            [...bg.children].forEach(item => item.remove());

            Background.canvas = document.createElement("canvas");
            Background.canvas.width = 1366;
            Background.canvas.height = 768;
            Background.context = Background.canvas.getContext("2d");
            bg.appendChild(Background.canvas);

            Background.images = [
                {
                    url: "bg/bg-4.jpg"
                }, {
                    url: "bg/bg-3.jpg"
                }, {
                    url: "bg/bg-2.jpg"
                }, {
                    url: "bg/bg-1.jpg"
                }
            ];
            Background.NB_IMAGES = Background.images.length;
            Background.PERIODE = Background.CYCLE / Background.NB_IMAGES;
            Background.increment = 0;
            Background.images.forEach(img => {
                var image = new Image();
                image.onload = () => Background.updateIncrement(img, image);
                image.onerror = () => Background.updateIncrement(img);
                image.src = PATH + img.url;
            })
        }
    },

    updateIncrement: function (img, image) {
        img.img = image;
        if (++Background.increment === Background.images.length) Background.render();
    },

    render: function (t = 0) {
        let percent = (t % Background.PERIODE) / Background.PERIODE;
        let currentImage = Math.floor((t % Background.CYCLE) / Background.PERIODE);
        let lastImage = (currentImage - 1 + Background.NB_IMAGES) % Background.NB_IMAGES;

        Background.context.globalAlpha = 1;
        Background.context.drawImage(Background.images[lastImage].img, 0, 0);
        Background.context.globalAlpha = percent;
        Background.context.drawImage(Background.images[currentImage].img, 0, 0);

        requestAnimationFrame(Background.render);
    }
};


export default Background;
