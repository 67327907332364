'use strict';

import fitty from "fitty";

const Title = {
    fittiesH1:null,
    fittiesH2:null,
    fittiesH3:null,
    fittiesMessage:null,
    init: function () {
        Title.resize();
        document.addEventListener('DOMContentLoaded', () => {
            setTimeout(() => {
                Title.resize();
            }, 400);
        });
    },
    resize: function () {
        if (window.matchMedia('(min-width:1366px)').matches) {
            Title.fittiesH1 = fitty('.h1:not(.mini)', {
                // minSize: 126,
                minSize: 10,
                maxSize: 225,
            });
            Title.fittiesH2 = fitty('.h2', {
                // minSize: 112,
                minSize: 10,
                maxSize: 200,
            });
            Title.fittiesMessage = fitty('.f-message a', {
                minSize: 44,
                // minSize: 10,
                maxSize: 78,
            });
            Title.fittiesH3 = fitty('.f-slider .h3', {
                // minSize: 50,
                minSize: 10,
                maxSize: 70,
            });
        } else {
            Title.fittiesH1 = fitty('.h1:not(.mini)', {
                // minSize: 35,
                minSize: 10,
                maxSize: 126,
            });
            Title.fittiesH2 = fitty('.h2', {
                // minSize: 34,
                minSize: 10,
                maxSize: 112,
            });
            Title.fittiesMessage = fitty('.f-message a', {
                minSize: 23,
                maxSize: 44,
            });
            Title.fittiesH3 = fitty('.f-slider .h3', {
                // minSize: 40,
                minSize: 10,
                maxSize: 60,
            });
        }

    }
};


export default Title;
