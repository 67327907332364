'use strict';

const FormCheckPwd = {
    els: null,
    init: function () {
        FormCheckPwd.els = document.querySelectorAll('.check-pwd');

        if (FormCheckPwd.els) {
            // Check password
            FormCheckPwd.els.forEach(passwordInput => {
                // Reset
                const passwordInputFormGroup = passwordInput.closest('.form-group');
                const lis = passwordInputFormGroup.querySelectorAll('.form-legend li');
                lis.forEach(li => li.classList.remove('incorrect'))
                passwordInput.addEventListener('keyup', () => FormCheckPwd.check(passwordInput));
                passwordInput.addEventListener('paste', (e) => {
                    const timer = setTimeout(()=> {
                        FormCheckPwd.check(e.target);
                        clearTimeout(timer);
                    }, 100)
                });
            });
        }
    },
    check: function (passwordInput) {
        const value = passwordInput.value;

        const passwordInputFormGroup = passwordInput.closest('.form-group');
        // Reset
        const lis = passwordInputFormGroup.querySelectorAll('.form-legend li');
        lis.forEach(li => {
            if (li.classList.contains('incorrect')) {
                li.classList.remove('incorrect');
            }
        })

        // check length (min 8)
        const lengthCheck = passwordInputFormGroup.querySelector('#lengthCheck');
        if (FormCheckPwd.checkLength(value)) {
            lengthCheck.classList.remove('incorrect');
            lengthCheck.classList.add('correct');
        } else {
            lengthCheck.classList.remove('correct');
            lengthCheck.classList.add('incorrect');
        }

        // Check Majuscule
        const majCheck = passwordInputFormGroup.querySelector('#majCheck');
        if (FormCheckPwd.checkMaj(value)) {
            majCheck.classList.remove('incorrect');
            majCheck.classList.add('correct');
        } else {
            majCheck.classList.remove('correct');
            majCheck.classList.add('incorrect');
        }

        // Check Minuscule
        const minCheck = passwordInputFormGroup.querySelector('#minCheck');
        if (FormCheckPwd.checkMin(value)) {
            minCheck.classList.remove('incorrect');
            minCheck.classList.add('correct');
        } else {
            minCheck.classList.remove('correct');
            minCheck.classList.add('incorrect');
        }

        // Check number
        const numberCheck = passwordInputFormGroup.querySelector('#numberCheck');
        if (FormCheckPwd.checkNumber(value)) {
            numberCheck.classList.remove('incorrect');
            numberCheck.classList.add('correct');
        } else {
            numberCheck.classList.remove('correct');
            numberCheck.classList.add('incorrect');
        }

        // Check special chars
        const specialCharsCheck = passwordInputFormGroup.querySelector('#specialCharsCheck')
        if (FormCheckPwd.checkSpecialChar(value)) {
            specialCharsCheck.classList.remove('incorrect');
            specialCharsCheck.classList.add('correct');
        } else {
            specialCharsCheck.classList.remove('correct')
            specialCharsCheck.classList.add('incorrect')
        }

    },
    checkLength: function (string) {
        let check = false;
        if (string.length >= 8) check = true;
        return check;
    },
    checkMaj: function (string) {
        let check = false;
        string.split('').forEach(char => {
            if (char.toUpperCase() === char && char.match(/[a-z]/i)) check = true;
        })
        return check;
    },
    checkMin: function (string) {
        let check = false;
        string.split('').forEach(char => {
            if (char.toLowerCase() === char && char.match(/[a-z]/i)) check = true;
        })
        return check;
    },
    checkNumber: function (string) {
        let check = false
        string.split('').forEach(char => {
            if (char.match(/[0-9]/i)) check = true;
        })
        return check;
    },
    checkSpecialChar: function (string) {
        let check = false
        string.split('').forEach(char => {
            if (!char.match(/[0-9]/i) && !char.match(/[a-z]/i)) check = true;
        })
        return check;
    }

}


export default FormCheckPwd;