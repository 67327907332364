'use strict';

import Filter from './Filter';
import Search from './Search';

const axios = require('axios');

const Calendar = {
    els: null,
    url: null,
    todayMonth:new Date().getMonth()+1,
    todayYear:new Date().getFullYear(),
    todayDate:new Date().getFullYear()+'-'+String(parseInt(new Date().getMonth()+1)).padStart(2, '0')+"-"+String(new Date().getDate()).padStart(2, '0'),
    filterDate : '',// !='' si on click sur un jour précis
    initMonths:true,// si on initialise le selecteur de mois (false si déja fait)
    totalMonths:'', // nombre de mois dans le selecteur

    getDays:function(month, year) {
        Calendar.hideDays();
        Calendar.hideMonths();
        axios({
            method: "get",
            url: Search.urlCalendar+'&current_month='+Calendar.todayMonth+'&current_year='+Calendar.todayYear+'&filter_month='+month+'&filter_year='+year+'&init='+Calendar.initMonths,
        }).then(function (response) {
                //handle success
                if (response.status === 200) {
                    Calendar.setDays(response.data.month_calendar);
                    if(Calendar.initMonths) {
                        Calendar.setMonth(response.data.list_months, month, year);
                    }
                    Calendar.showMonths();
                } else {
                    console.log('errors', response.statusText);
                }
            }
        ).catch(function (response) {
            //handle error
            console.log('err', response);
        });
    },
    setMonth: function(months, cMonth, cYear) {
        let html='';
        let label='';
        let currentId='';
        Calendar.totalMonths = Object.keys(months).length;
        for (const month in months) {
            if(cMonth+'-'+cYear === months[month].month+'-'+months[month].year) {
                label=months[month].label;
                currentId = month;
            }
            html+=`<li role="listitem" class="${cMonth+'-'+cYear === months[month].month+'-'+months[month].year ? "selected--" : ''}" data-key="${month}" data-month="${months[month].month}" data-year="${months[month].year}" data-label="${months[month].label}"><span>${months[month].label}</span></li>`;
        }
        document.querySelector('.c-calendar__current span').innerHTML = label;
        document.querySelector('.c-calendar__options-list').innerHTML = html;
        document.querySelector('.c-calendar__month').classList.add('show--');

        Calendar.initMonths=false;
        Calendar.initSelect();
        Calendar.setNextPrev(currentId);
    },
    setDays: function(days) {
        let html='';
        if(Object.keys(days).length > 0) {
            html = '<div><ul role="list">'
        }
        for (const day in days) {
            html+=`<li role="listitem" class="${days[day].date === Calendar.filterDate ? "filter--" : ''} ${days[day].date === Calendar.todayDate ? "current--" : ''}"><button type="button" data-filterDay="${days[day].date}" ${days[day].has_event===false || new Date(Calendar.todayDate) > new Date(days[day].date) ? "disabled" : ''}><div>${days[day].week_day}<span>${days[day].day}</span></div></button>`;
            if(days[day].date === Calendar.filterDate) {
                html += '<span class="c-calendar__filter"><svg width="44" height="44" xmlns="http://www.w3.org/2000/svg" class="neon-circle">\n' +
                '    <circle class="neon-circle__item blur--" cx="50%" cy="50%" r="18" fill="none"/>\n' +
                '    <circle class="neon-circle__item" cx="50%" cy="50%" r="18"  fill="none"/>\n' +
                '    <circle class="neon-circle__item blur-- blurfront--" cx="50%" cy="50%" r="18" fill="none"/>\n' +
                '</svg></span>';
            }
            html+`</li>`;
        };
        if(Object.keys(days).length > 0) {
            html += '</ul></div>'
        }
        document.querySelector('.c-calendar__days').innerHTML = html;
        Calendar.showDays();
        Calendar.eventDays();
    },
    initSelect: function () {
        if (document.querySelector('.c-calendar__select')) {
            let el = document.querySelector('.c-calendar__select');
            let currentMonth = el.querySelector('.c-calendar__current');
            let options = el.querySelectorAll('.c-calendar__options-list li');

            // Open dropdown panel
            el.addEventListener('click', () => {
                if(!el.classList.contains('show-options')) {
                    el.classList.add('show-options');
                    setTimeout(function () {
                        el.classList.add('anim-options');
                    }, 50);

                    setTimeout(function () {
                        el.classList.add('show-shadow');
                    }, 200);
                }
                else {
                    Calendar.closeSelect(el);
                }
            });

            // Close dropdown panel
            if (options) {
                options.forEach(option => {
                    option.addEventListener('click', (e) => {
                        e.stopPropagation();
                        Calendar.selectMonth(option);
                    });
                });

                document.querySelector('.c-calendar__month-prev').addEventListener('click', (e) => {
                    e.stopPropagation();
                    const number = parseInt(el.querySelector('.selected--').dataset.key)-1;
                    const current = el.querySelector('li:nth-child('+number+'n)')
                    Calendar.selectMonth(current);
                });


                document.querySelector('.c-calendar__month-next').addEventListener('click', (e) => {
                    const number = parseInt(el.querySelector('.selected--').dataset.key)+1;
                    const current = el.querySelector('li:nth-child('+number+'n)')
                    e.stopPropagation();
                    Calendar.selectMonth(current);
                });
            }


            // Clicked outside dropdown panel
            window.addEventListener('click', function (event) {
                const target = event.target || event.srcElement || event.currentTarget;
                if (!el.contains(target) && el.classList.contains('show-options')) {
                    Calendar.closeSelect(el);
                }
            });
        }
    },
    selectMonth: function(elt) {
        //label à afficher dans le select //month et year selectionné //key courante //elt selectionné
        Calendar.closeSelect(document.querySelector('.c-calendar__select'));//fermeture du select si ouvert
        document.querySelector('.c-calendar__select .c-calendar__current span').innerHTML = elt.dataset.label;
        document.querySelector('.c-calendar__select .selected--')?.classList.remove('selected--');
        elt.classList.add('selected--');
        Search.updateParams('month_', elt.dataset.month);
        Search.updateParams('year_', elt.dataset.year);
        Calendar.getDays(elt.dataset.month, elt.dataset.year);
        Calendar.setNextPrev(elt.dataset.key);
        Search.removeParams('page_');
        Search.removeParams('date_');
        Search.getParams();
    },
    showMonths: function() {
        document.querySelector('.c-calendar__month').classList.add('show--');
    },
    hideMonths: function() {
        document.querySelector('.c-calendar__month').classList.remove('show--');
    },
    showDays: function() {
        document.querySelector('.c-calendar__days').classList.add('show--');
        let params = (new URL(document.location)).searchParams;
        let eltFilter = document.querySelector('.c-calendar__days');

        if(window.matchMedia("(max-width: 768px)").matches) {
            if(params.get('date_')) {//sil y a une date dans l'url
                if(eltFilter.querySelector('li.filter--')) {
                    let offsetLeft = eltFilter.querySelector('li.filter--').offsetLeft;
                    if(offsetLeft > window.innerWidth/2) {
                        eltFilter.querySelector('div').scrollLeft = offsetLeft - (window.innerWidth/2) + (eltFilter.querySelector('li.filter-- ').offsetWidth/2);
                    }
                }
            }
            else if (!params.get('month_') && !params.get('year_') || (params.get('month_') == Calendar.todayMonth && params.get('year_')== Calendar.todayYear)) {
                let offsetLeft = eltFilter.querySelector('li.current--').offsetLeft;
                if(offsetLeft > window.innerWidth/2) {
                    eltFilter.querySelector('div').scrollLeft = offsetLeft - (window.innerWidth/2) + (eltFilter.querySelector('li.current--').offsetWidth/2);
                }
            }
            else {
                if(eltFilter.querySelector('li.current--')) {
                    let offsetLeft = eltFilter.querySelector('li.current--').offsetLeft;
                    if(offsetLeft > window.innerWidth/2) {
                        eltFilter.querySelector('div').scrollLeft = offsetLeft - (window.innerWidth/2) + (eltFilter.querySelector('li.current--').offsetWidth/2);
                    }
                }
            }
        }
    },
    hideDays: function() {
        document.querySelector('.c-calendar__days').classList.remove('show--');
    },
    setNextPrev: function(currentId) {
        if(currentId <= 1) {
            document.querySelector('.c-calendar__month-prev').disabled = true;
        }
        else {
            document.querySelector('.c-calendar__month-prev').disabled = false;
        }
        if(currentId >= Calendar.totalMonths) {
            document.querySelector('.c-calendar__month-next').disabled = true;
        }
        else {
            document.querySelector('.c-calendar__month-next').disabled = false;
        }
    },
    closeSelect: function (elt) {
        elt.classList.remove('anim-options');
        elt.classList.remove('show-shadow');
        setTimeout(function () {
            elt.classList.remove('show-options');
        }, 600);
    },
    eventDays: function() {
        document.querySelectorAll('.c-calendar__days button').forEach((item) => {
            item.addEventListener('click', function(e) {
                e.stopPropagation();
                Calendar.filterDate = item.dataset.filterday;
                if(!item.parentNode.classList.contains('filter--')) {
                    Calendar.removeFilterDate();
                    Calendar.addFilterDate(item.parentNode);
                    Search.updateParams('date_', item.dataset.filterday);
                    Search.removeParams('month_');
                    Search.removeParams('year_');
                    Search.removeParams('genre_');
                    Search.removeParams('page_');
                    Filter.removeFilter();
                }
                else {
                    Calendar.removeFilterDate();
                    Search.removeParams('date_');
                    Search.removeParams('page_');
                }
                Search.getParams();
            })
        })
    },
    addFilterDate:function(elt) {
        elt.classList.add('filter--');
        let html = '<span class="c-calendar__filter"><svg width="44" height="44" xmlns="http://www.w3.org/2000/svg" class="neon-circle">\n' +
        '    <circle class="neon-circle__item blur--" cx="50%" cy="50%" r="18" fill="none"/>\n' +
        '    <circle class="neon-circle__item" cx="50%" cy="50%" r="18"  fill="none"/>\n' +
        '    <circle class="neon-circle__item blur-- blurfront--" cx="50%" cy="50%" r="18" fill="none"/>\n' +
        '</svg></span>';
        elt.insertAdjacentHTML('beforeend', html);
    },
    removeFilterDate: function() {
        if(document.querySelector('.c-calendar__days li.filter--')) {
            document.querySelector('.c-calendar__days li.filter-- .c-calendar__filter').remove();
            document.querySelector('.c-calendar__days li.filter--').classList.remove('filter--');
            Calendar.filterDate = '';
        }
    }
};

export default Calendar;
