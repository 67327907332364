'use strict';
const axios = require('axios');
import Search from "./Search";


const Pages = {
    eltResults: '',
    container:null,
    postPerPage: 100,
    
    getPages: function(page, keyword) {
        Pages.eltResults = Search.eltPagesResults.querySelector('.t-search__results');
        Pages.container = Search.container.querySelector('.t-search__pages');
        if(Pages.eltResults) {
            axios({
                method: "get",
                url: `${Search.urlSearchPages}&posts_per_page=${Pages.postPerPage}&keyword=${keyword}`,
            }).then(function (response) {
                    //handle success
                    if (response.status === 200) {
                        Pages.showPages(response.data);
                    } else {
                        Pages.eltResults.innerHTML = 'Error';
                        Search.hideLoader();
                        console.log('errors', response.statusText);
                    }
                }
            ).catch(function (response) {
                //handle error
                console.log('err', response);
                Pages.eltResults.innerHTML = '<p class="noresult">Error</p>';
                Search.hideLoader();
            });
        }
    },
    showPages : function(data) {
        let html = '';
        if(data.total>0 && data.items) {
            data.items.forEach((item) => {
                html+=`<div class="c-wysiwyg c-pages">
                        <h3 class="h4">${item.post_title}</h3>`;
                        if(item.meta._seopress_titles_desc) {
                            html+=`<p>${item.meta._seopress_titles_desc}</p>`;
                        }
                        html+=`<p><a href="${item.permalink}" class="c-button link-- red--"><span>${Search.eltPagesResults.dataset.more}</span></a></p>
                    </div>
                `;
            })
            if (data.total) {
                Search.totalResults = Search.totalResults + data.total;
            }
            if(Pages.container) {
                Pages.container.classList.remove('hide--');
            }
        }
        else {
            //No results
            if(Pages.container) {
                Pages.container.classList.add('hide--');
            }
            html+=`<p class="noresult">${Search.eltPagesResults.dataset.noresults}</p>`;
        }
        Search.hideLoader();
        Pages.eltResults.innerHTML = html;
    }
};

export default Pages;
