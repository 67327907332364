import {deleteFromCart} from "../utils/cart-apis";
import CartSummary from "./CartSummary";
import Tunnel from "./Tunnel";
const Popin = {
    buttons:null,
    popinElts:null,
    init: function () {
        Popin.popinElts = document.querySelectorAll('.c-popin');
        if (Popin.popinElts && Popin.popinElts.length > 0) {
            Popin.popinElts.forEach((elt) => {
                if(elt.querySelectorAll('.c-popin__close')) {
                    elt.querySelectorAll('.c-popin__close').forEach((btn)=> {
                        btn.addEventListener('click', (e) => {
                            e.preventDefault();
                            Popin.closePopin(elt);
                        });
                    })
                }

                if(elt.querySelector('.c-popin__overlay')) {
                    elt.querySelector('.c-popin__overlay').addEventListener('click', (e) => {
                        e.preventDefault();
                        Popin.closePopin(elt);
                    });
                }

                if(elt.querySelector('.c-popin__formConfirm')) {//formulaire de confirmation de suppression
                    elt.querySelector('.c-popin__formConfirm').addEventListener('submit', (e) => {
                        e.preventDefault();
                        const showId = elt.querySelector('.confirmShowId').value;
                        const entryId = elt.querySelector('.confirmEntryId').value;
                        if(showId && entryId) {
                            Tunnel.loadingPage();
                            window.scrollTo({top: 0 });
                            deleteFromCart(showId, { "entryId" : entryId }, CartSummary.removeCartInfo, CartSummary.showError);
                            Popin.closePopin(elt);
                        }
                    })
                }
            });

            Popin.buttons = document.querySelectorAll('[data-popin]');
            if(Popin.buttons && Popin.buttons.length>0){
                Popin.buttons.forEach(button =>{
                    button.addEventListener('click', () => {
                        const targetModal = button.dataset.popin;
                        const modal = document.querySelector('.c-popin#'+targetModal);
                        if(modal){
                            Popin.showPopin(modal);
                        }
                    });
                });
            }
        }
    },
    showPopin: function(elt, showId="", entryId="") {
        elt.classList.add('open--');
        if(elt.querySelector('.confirmShowId') && elt.querySelector('.confirmEntryId')) {
            elt.querySelector('.confirmShowId').value=showId;
            elt.querySelector('.confirmEntryId').value=entryId;
        }
    },
    closePopin: function(elt) {
        if(elt.classList.contains('countdown--') && !elt.classList.contains('middleTime--')) {
            window.location = "/";
        }
        else {
            elt.classList.remove('open--');
            if(elt.id==="modal-promocode" || elt.id==="followArtist"){
                elt.querySelector('form').reset();
                elt.querySelector('.success').classList.remove('active');
                elt.querySelector('.form-group.submit').classList.remove('sent');
                elt.querySelector('.error.global--').classList.remove('active');
                const formGroups = elt.querySelectorAll('.form-group');
                formGroups.forEach(formGroup => {
                    formGroup.classList.remove('error');
                });
            }
        }
    }
};

export default Popin;