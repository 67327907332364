
'use strict';
import Search from "./Search";
const Filter = {
    elts:'',//liste des blocs filter (popin et page)
    filterList:'',//liste des filtres selectionnés (unique sur la page)
    url:'',
    init:function() {
        Filter.elts = document.querySelectorAll(".c-filter");
        if(Filter.elts.length > 0) {
            Filter.filterList = document.querySelector('.c-filter__list');
            Filter.getPeriodsFilters();
        }
    },
    getPeriodsFilters: function() {
        let html = '';
        const lang = document.documentElement.lang.substr(0, 2);
        let months = []
        if(lang == "en") {
            months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        }
        else {
            months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        }
        let currentMonth = new Date().getMonth()+2;
        let currentYear = new Date().getFullYear();
        for(let i=0; i<4; i++) {
            if(currentMonth > 12) {
                currentMonth = 1;
                currentYear++;
            }
            // String(parseInt(new Date().getMonth()+1)).padStart(2, '0')
            html+=`<li><button type="button" data-period="month-${currentYear}-${String(currentMonth).padStart(2, '0')}"><span>${months[currentMonth - 1]}</span></button></li>`;
            currentMonth++;
        }
        html+=`<li><button type="button" data-period="year-${new Date().getFullYear()+1}"><span>${new Date().getFullYear()+1}</span></button></li>`;

        document.querySelectorAll('.c-filter__cat.period-- ul').forEach(elt => {
            elt.insertAdjacentHTML('beforeend', html);
        })

        Filter.eventFilter();
    },
    eventFilter:function() { //Evenement ouverture/fermeture popin + close
        Filter.elts.forEach(filterElt => {
            filterElt.querySelector('.c-filter__overlay').addEventListener('click', () => {
                Filter.closeFilter();
              // Filter.removeFilter();
                Search.getParams();
            });

            filterElt.querySelector('.c-filter__close').addEventListener('click', () => {
                Filter.closeFilter();
             //  Filter.removeFilter();
                Search.getParams();
            });

            filterElt.querySelector('.c-filter__titleBtn').addEventListener('click', (e) => {// au  click sur le bouton toggle Filtrer
                const btn = filterElt.querySelector('.c-filter__titleBtn');
                if(btn.classList.contains('open--')) {
                    Filter.closeFilter();
                }
                else {
                    Filter.openFilter(filterElt);
                }
            });

            if(filterElt.querySelectorAll('.c-filter__deleteSelected')) { //delete all active filter on elementFilter
                filterElt.querySelectorAll('.c-filter__deleteSelected').forEach(btnDelete => {
                    btnDelete.addEventListener('click', (e) => {
                        Search.removeParams("page_");
                        filterElt.querySelectorAll('.c-filter__cat button.active--').forEach((elt) => {
                            elt.classList.remove("active--");
                        });
                    });
                })
                
            }

            filterElt.querySelectorAll('.c-filter__cat button').forEach((item) => { //add or remove active filter on click on elementFilter
                item.addEventListener('click', () => {
                    item.classList.toggle('active--');
                });
            });

            if(filterElt.querySelector('.c-filter__form')) {
                filterElt.querySelector('.c-filter__form').addEventListener('submit', (e) => { //on submit form filter
                    e.preventDefault();
                    Search.removeParams("page_");
                    if(e.target.closest('.c-popin')) {
                        Filter.submitSearchPopin();//On submit ds la popin
                    }
                    else {//On submit ds la page
                        Filter.closeFilter();
                        Filter.submitSearch();
                    }
                });
            }
        })
    },
    openFilter: function(filterElt) {// Open accoredon filter
        const filterContent = filterElt.querySelector(".c-filter__content");
        if(!filterElt.querySelector('.c-filter__title button.open--')) {
            filterElt.classList.add('open--');
            filterContent.classList.add('show-options');
            setTimeout(function () {
                filterContent.classList.add('anim-options');
            }, 50);

            setTimeout(function () {
                filterContent.classList.add('show-shadow');
                filterElt.querySelector('.c-filter__title button:not(.c-filter__deleteSelected)').classList.add('open--');
            }, 200);
        }
    },
    closeFilter: function() { //Close accordeon filter
        const filterElt = document.querySelector('.c-filter.open--');
        if(filterElt) {
            const filterContent = filterElt.querySelector(".c-filter__content");

            filterElt.classList.remove('open--');
            filterContent.classList.remove('anim-options');
            filterContent.classList.remove('show-shadow');

            setTimeout(function () {
                filterContent.classList.remove('show-options');
                filterElt.querySelector('.c-filter__title button').classList.remove('open--');
            }, 600);
        }
    },
    submitSearchPopin: function() { //a la validation des filtres dans la popin
        if(Filter.url) {
            let params = ""
            if(document.getElementById('search_textPopin').value !='') {
                if(params=='') { params+="?"} else { params+='&'}
                params+= "keyword_="+document.getElementById('search_textPopin').value;
            }
            const tabloGenre = Filter.getActiveFilterGenre('', true);
            if(tabloGenre.length > 0) {
                if(params=='') { params+="?"} else { params+='&'}
                let values = tabloGenre;
                if(Array.isArray(tabloGenre)){
                    values = tabloGenre.join('_');
                }
                params+= "genre_="+values;
            }

            const tabloPeriod = Filter.getActiveFilterPeriods('', true);
            if(tabloPeriod.length > 0) {
                if(params=='') { params+="?"} else { params+='&'}
                let values = tabloPeriod;
                if(Array.isArray(tabloPeriod)){
                    values = tabloPeriod.join('_');
                }
                params+= "periods_="+ values;
            }
            window.location = Filter.url+params;
        }
    },
    submitSearch: function() {
        if(document.getElementById('search_text')) {
            if(document.getElementById('search_text').value !='') {
                Search.updateParams("keyword_", document.getElementById('search_text').value);
            }
            else {
                Search.removeParams("keyword_");
            }
        }
        const tabloGenre = Filter.getActiveFilterGenre();
        if(tabloGenre.length > 0) {
            Search.updateParams("genre_", tabloGenre);
        }
        else {
            Search.removeParams("genre_");
        }

        const tabloPeriod = Filter.getActiveFilterPeriods();
        if(tabloPeriod.length > 0) {
            Search.updateParams("periods_", tabloPeriod);
        }
        else {
            Search.removeParams("periods_");
        }

        if(tabloGenre.length === 0 && tabloPeriod.length === 0) {
            Filter.removeFilter();
        }

        Filter.activeFilter(tabloGenre, tabloPeriod, false);
        Search.getParams();
    },
    getActiveFilterGenre: function(except = '', popin=false) { //recuperation de tous les filtres actifs Genre //except pemet d'enlever un elt de la liste
        let tablo = [];
        let list = null;
        if(popin) {
            list = document.querySelectorAll('.c-filter.popin-- .c-filter__cat.genre-- button.active--')
        }
        else {
            list = document.querySelectorAll('.c-filter:not(.popin--) .c-filter__cat.genre-- button.active--')
        }
        list.forEach((item)=> {
            if(except != item.dataset.genre)
                tablo.push(item.dataset.genre);

        })
        return tablo;
    },
    getActiveFilterPeriods: function(except = '', popin = false) { //recuperation de tous les filtres actifs Period //except pemet d'enlever un elt de la liste
        let tablo = [];
        let list = null;
        if(popin) {
            list = document.querySelectorAll('.c-filter.popin-- .c-filter__cat.period-- button.active--')
        }
        else {
            list = document.querySelectorAll('.c-filter:not(.popin--) .c-filter__cat.period-- button.active--')
        }
        list.forEach((item)=> {
            if(except != item.dataset.period)
                tablo.push(item.dataset.period);

        })
        return tablo;
    },
    activeFilter: function(filtersGenre, filtersPeriod, activePopin) { //MAJ de la liste des filtres actifs //Si activePopin=true maj dans la popin filtre
        const filterElt = document.querySelector('.c-filter:not(.popin--)');
        Filter.removeFilter();
        if(filtersGenre.length > 0 || filtersPeriod.length > 0) {
            let activeList = '<ul role="list">';
                if(filtersGenre.length > 0) {
                    filtersGenre.forEach((value) => {
                        const elt = filterElt.querySelector('.c-filter__form button[data-genre='+value+']');
                        if(elt) {
                            if(activePopin) {
                                elt.classList.add('active--');
                            }
                            activeList += `<li role="listitem"><button type="button" class="active--" data-genre="${value}"><span>${elt.innerHTML}</span></button></li>`;
                        }
                    });
                }
                if(filtersPeriod.length > 0) {
                    filtersPeriod.forEach((value) => {
                        const elt = filterElt.querySelector('.c-filter__form button[data-period='+value+']');
                        if(elt) {
                            if(activePopin) {
                                elt.classList.add('active--');
                            }
                            activeList += `<li role="listitem"><button type="button" class="active--" data-period="${value}"><span>${elt.innerHTML}</span></button></li>`;
                        }
                    });
                }
            activeList += '</ul>';
            activeList += `<button type="button" class="c-filter__delete onlyDesktop--">${filterElt.dataset.supprall}</button>`;

            Filter.filterList.innerHTML = activeList;
            Filter.eventFilterList();
        }
        else {
            if(Search.container.querySelector('.c-filter__list')) {
                Search.container.querySelector('.c-filter__list').innerHTML = '';
            }
        }
    },
    removeFilter: function() { //supprime tous les filtres dans laccordeon filtre de la page
        Filter.filterList.innerHTML = '';
        Search.container.querySelectorAll('.c-filter__form button.active--').forEach((item)=> {
            item.classList.remove('active--');
        });
    },
    eventFilterList: function() { //evenement sur les filtres dans la liste (suppression 1 filtre et bouton tout supprimer)
        document.querySelector('.c-filter__delete').addEventListener('click', (e) => {
            e.preventDefault();
            Search.removeParams("genre_");
            Search.removeParams("periods_");
            Search.removeParams("page_");
            Filter.removeFilter();
            Search.getParams();
        });

        Filter.filterList.querySelectorAll('ul li button').forEach((elt) => {
            elt.addEventListener('click', (e) => {
                e.preventDefault();
                Search.removeParams("page_");
                if(elt.dataset.genre) {
                    let tablo = Filter.getActiveFilterGenre(elt.dataset.genre);
                    if(tablo.length > 0) {
                        Search.updateParams("genre_", tablo);
                    }
                    else {
                        Search.removeParams("genre_");
                    }
                    if(document.querySelector('.c-filter__form button[data-genre='+elt.dataset.genre+']'))
                        document.querySelector('.c-filter__form button[data-genre='+elt.dataset.genre+']').classList.remove('active--');
                }

                if(elt.dataset.period) {
                    let tabloPeriod = Filter.getActiveFilterPeriods(elt.dataset.period);
                    if(tabloPeriod.length > 0) {
                        Search.updateParams("periods_", tabloPeriod);
                    }
                    else {
                        Search.removeParams("periods_");
                    }
                    if(document.querySelector('.c-filter__form button[data-genre='+elt.dataset.period+']'))
                        document.querySelector('.c-filter__form button[data-period='+elt.dataset.period+']').classList.remove('active--');
                }


                Search.getParams();
            })
        });
    }
};

export default Filter;