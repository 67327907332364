import StickyButton from "./stickyButton";

const Tunnel = {
    loadingPage: function() {
        if(document.querySelector('.main.t-tunnel')){
            document.querySelector('.main.t-tunnel').classList.add('loading--');
        }
    },
    showPage:function() {
        if(document.querySelector('.main.t-tunnel')){
            if( document.querySelector('.main.t-tunnel').classList.contains('loading--')){
                document.querySelector('.main.t-tunnel').classList.remove('loading--');
            }
            StickyButton.makeSticky();
        }

    }
}

export default Tunnel;