import Popin from "./Popin";
import {deleteAllCart} from "../utils/cart-apis";
import {getLocalStorage, removeLocalStorage, saveLocalStorage} from "../utils/local-storage";


const CountdownCart = {
    name:"cart_expiration_date",//nom de la localstorage
    popinElt:null,
    stepInterval:300000,//en ms
    timeinterval: null,
    init: function () {
        CountdownCart.popinElt = document.getElementById("countDown");
        if(CountdownCart.popinElt) {
            CountdownCart.events();
            if(!document.querySelector('.c-cartSummary')) {
                CountdownCart.checkCart();
            }
        }
    },
    events: function() {
        CountdownCart.popinElt.querySelector('.c-popin__delete').addEventListener('click', (e) => {
            e.preventDefault();
            deleteAllCart(CountdownCart.deleteCart);
        });
    },
    checkTimer: function(validTimerCallback, invalidTimerCallback, invalidTimer2Callback){
        if(getLocalStorage("cart_expiration_date")) { //il y a une date de fin dans le localstorage
            if(getTimeRemaining(getLocalStorage("cart_expiration_date")).total >0) {//le decompte est encore valable
                validTimerCallback(getLocalStorage("cart_expiration_date"));
            }
            else {
                console.log("le decompte est plus valable")
                invalidTimerCallback();
            }
        } else {
            console.log("le decompte est impossible")
            if(invalidTimer2Callback){
                invalidTimer2Callback();
            }
        }
    },
    checkCart: function() {
        if(CountdownCart.timeinterval) { //sil y a deja un decompte en cours on le clear
            clearInterval(CountdownCart.timeinterval);
        }
        CountdownCart.checkTimer((date) => {
            CountdownCart.timer(date); //lancement du décompte
        }, () => {
            CountdownCart.showEndTime('end');
        });
    },
    timer: function(endTime) {
        const minutesSpan = document.querySelectorAll('.c-timer__countdown .minutes'),
              secondsSpan = document.querySelectorAll('.c-timer__countdown .seconds');

        CountdownCart.timeinterval = setInterval(function () {
            const time = getTimeRemaining(endTime);

            minutesSpan.forEach(minute => {
                minute.innerHTML = ('0' + time.minutes).slice(-2);
            });
            secondsSpan.forEach(second => {
                second.innerHTML = ('0' + time.seconds).slice(-2);
            });

            if(time.total === CountdownCart.stepInterval) {
                //Verification qu'il y a qqchose ds le panier
                CountdownCart.showMiddleTime();
            }
            if (time.total <= 0) {
                CountdownCart.showEndTime();
            }
        }, 1000);
    },
    deleteCart: function(redirect=true) {
        if(redirect) {
            if(document.querySelector('.c-cartSummary') || document.querySelector('.t-deliveryMode')){
                CountdownCart.removeInfosLocal();
                window.location.href='/';
            }
            else {
                if(getLocalStorage('cart_nb_entry') && getLocalStorage('cart_nb_entry') > 0) {
                    CountdownCart.removeInfosLocal();
                    window.location.reload();
                }
            }
        }
    },
    showMiddleTime: function() {
        if(getLocalStorage('cart_nb_entry') && getLocalStorage('cart_nb_entry') > 0 && !document.querySelector('.t-thanks')) {
            CountdownCart.popinElt.classList.add('middleTime--');
            Popin.showPopin(CountdownCart.popinElt);
        }
        else if(document.querySelector('.t-thanks')) {
            CountdownCart.removeInfosLocal();
        }
    },
    showEndTime: function() {
        if(getLocalStorage('cart_nb_entry') && getLocalStorage('cart_nb_entry') > 0 && !document.querySelector('.t-thanks')) {
            CountdownCart.popinElt.classList.remove('middleTime--');
            CountdownCart.removeInfosLocal();
            Popin.showPopin(CountdownCart.popinElt);
        }
        else if(document.querySelector('.t-thanks')) {
            CountdownCart.removeInfosLocal();
        }
        CountdownCart.deleteCart((document.querySelector('.c-plan') && document.querySelector('.c-plan').length));
    },
    removeInfosLocal: function() {
        removeLocalStorage("cart_expiration_date");//suppression du localstorage
        removeLocalStorage("cart_id");//suppression du localstorage
        removeLocalStorage("token_cart");//suppression du localstorage
        removeLocalStorage("deliveryMode");//suppression du localstorage
        removeLocalStorage("userInfo");//suppression du localstorage
        removeLocalStorage("cart_nb_entry");//suppression du localstorage
        removeLocalStorage("access_code");//suppression du localstorage
        removeLocalStorage("codePrevente");//suppression du localstorage
        clearInterval(CountdownCart.timeinterval);
    }
};

function getTimeRemaining(endTime) {
    var dateInParis = new Date().toLocaleString('fr-FR', { timeZone: 'Europe/Paris' });
    var parts = dateInParis.split(/\D/);
    const dateInParisFormat = parts[2] + '-' + parts[1] + '-' + parts[0] + ' ' + parts[3] + ':' + parts[4] + ':' + parts[5];
    let total =  Date.parse(endTime) - Date.parse(dateInParisFormat);

    let seconds = Math.floor((total / 1000) % 60);
    let minutes = Math.floor((total / 1000 / 60) % 60);

    if (seconds < 0) {
        seconds = 0;
    }
    if (minutes < 0) {
        minutes = 0;
    }

    return {
        total,
        minutes,
        seconds
    };
}

export default CountdownCart;