import Header from "./components/Header";
import Languages from "./components/Languages";
import SliderSingle from "./blocks/bloc_slider_single_type3";
import SliderMulti from "./blocks/bloc_slider_multi";
//import Video from "./blocks/bloc_video";
import Accordion from "./blocks/bloc_accordeon";
import Form from "./components/Form";
//import Select from "./components/Select";
import Plan from "./components/Plan";
import SliderHeader from "./blocks/SliderHeader";
import Agenda from "./blocks/Agenda";
import Title from "./components/Title";
import Card from "./components/Card";
import Countdown from "./blocks/Countdown";
import News from "./blocks/News";
import TitleNeon from "./components/TitleNeon";
import Newsletter from "./components/Newsletter";
import { Cursor } from "./components/Cursor";
import PartnersDetails from "./blocks/PartnersDetails";
import StickyEvent from "./components/StickyEvent";
import Scroll from "./components/Scroll";
import Modal from "./components/Modal";
import CountdownCart from "./components/CountdownCart";
import StickyButton from "./components/stickyButton";
import Popin from "./components/Popin";
import Paiement from "./components/Paiement";
import CartSummary from "./components/CartSummary";
import DeliveryMode from "./components/DeliveryMode";
import Remerciement from "./components/Remerciement";
import Account from "./components/Account";
import Search from "./components/Search";
import FormCheckPwd from "./components/FormCheckPwd";
import Background from "./components/Background";
import Transactions from "./components/Transactions";

const main = {
    init: function () {
        const cursor = new Cursor({
            minWidth: '1025px',
            pointer: "auto",
            colorDefault: '',
            framerate: 75
        });

        Title.init();
        Header.init();

        Languages.init();
        Card.init();
        TitleNeon.init();
        Newsletter.init();
        SliderSingle.init();
        SliderMulti.init();
        // Video.init();
        Accordion.init();
        // Select.init();
        Form.init();
        Plan.init();
        SliderHeader.init();
        Agenda.init();
        Countdown.init();
        News.init();
        PartnersDetails.init();
        StickyEvent.init();
        Scroll.init();
        CountdownCart.init();
        Modal.init();
        StickyButton.init();
        Popin.init();
        Paiement.init();
        CartSummary.init();
        DeliveryMode.init();
        Remerciement.init();
        Account.init();
        Search.init();
        FormCheckPwd.init();
        Background.init();
        Transactions.init();
    },

};

export default main