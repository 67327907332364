'use strict';

const Newsletter = {
    els: null,
    init: function () {
        Newsletter.els = document.querySelectorAll('.c-newsletter');
        if (Newsletter.els && Newsletter.els.length > 0) {
            Newsletter.els.forEach(el => {
                const toggleButton = el.querySelector('.neon button');
                if (toggleButton) {
                    toggleButton.addEventListener('click', () => {
                        el.classList.toggle('step2--');
                    });
                }
            });
        }
    },
};

export default Newsletter;
