'use strict';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Card = {
    els: null,
    init: function () {
        Card.els = document.querySelectorAll('.c-card');
        if (Card.els && Card.els.length > 0) {
            Card.els.forEach((card,index) => {

                if(card.dataset.link) {
                    card.addEventListener('click', (e)=> {
                        window.location = card.dataset.link;
                    })
                }
                
                /*const content = card.querySelector('.c-card__content');
                  if (content) {
                         content.addEventListener('mouseenter', () => {
                             card.classList.add('hovered');
                         });
                         content.addEventListener('mouseleave', () => {
                             if (card.classList.contains('hovered')) {
                                 card.classList.remove('hovered');
                             }
                         });
                     }*/

                let trigger =  card;
                if(card.querySelector('.f-agenda__list')){
                    trigger = card.querySelector('.f-agenda__list')
                }

                gsap.set(card.querySelector('.c-card__img-ctn'), {
                    autoAlpha: 0,
                });
                gsap.to(card.querySelector('.c-card__img-ctn'), {
                    scrollTrigger: {
                        trigger: trigger,
                        toggleActions: "play none none none"
                    },
                    duration: .2,
                    autoAlpha: 1,
                    ease: "power1.in",
                    delay: parseFloat(0.2+(0.2*index)),
                });
                gsap.to(card.querySelector('.c-card__img'), {
                    scrollTrigger: {
                        trigger: trigger,
                        toggleActions: "play none none none"
                    },
                    duration: .2,
                    backgroundColor: "transparent",
                    ease: "power1.in",
                    delay: parseFloat(0.2+(0.2*index)),
                });
            });
        }
    },
};


export default Card;
