'use strict';

import Plan from "./Plan";

const axios = require("axios");

/*
function downloadURI(uri, name) {
    const link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', name);
    link.target = '_blank';
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
}
*/

/* Helper function */
function downloadURI(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        const filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
        if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = save.href;
// window event not working here
        }else{
            const evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
    }

    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand)     {
        const _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
}

const Transactions = {
    init: function () {
        let buttons = document.querySelectorAll('.c-cartSummary__download');
        if (buttons && buttons.length > 0) {
            buttons.forEach(button => {

                if (button.classList.contains('pdf--')) {
                    if (process.env.MIX_GET_EBILLET) {
                        button.addEventListener('click', () => {
                            const transactionId = button.dataset.ebillet_transaction_id;
                            const token = button.dataset.ebillet_token;
                            const ticketsId = button.dataset.ebillet_tickets_id;
                            const title = button.dataset.download;
                            //console.log(transactionId, token, ticketsId, title);

                            if (transactionId && token && ticketsId && title) {
                                button.classList.add('loading--');
                                const params = `${transactionId}/${ticketsId}/${token}`;
                                axios({
                                    method: "get",
                                    url: window.location.origin + '/wp-json/' + process.env.MIX_GET_EBILLET + params,
                                }).then(function (response) {
                                    //handle success
                                    //console.log(response);
                                    button.classList.remove('loading--');
                                    if(response.data.success === true){
                                        if(response.data.datas){
                                            downloadURI(response.data.datas, title);
                                        }
                                    }
                                }).catch(function (response) {
                                    //handle error
                                    console.log('err', response);
                                    button.classList.remove('loading--');
                                });
                            }
                        });
                    }
                }

                if (button.classList.contains('invoice--')) {
                    if (process.env.MIX_GET_INVOICE) {
                        button.addEventListener('click', () => {
                            const transactionId = button.dataset.invoice_transaction_id;
                            const token = button.dataset.invoice_token;
                            const title = button.dataset.download;
                            //console.log(transactionId, token, title);
                            if (transactionId && token && title) {
                                button.classList.add('loading--');
                                const params = `${transactionId}/${token}`;
                                axios({
                                    method: "get",
                                    url: window.location.origin + '/wp-json/' + process.env.MIX_GET_INVOICE + params,
                                }).then(function (response) {
                                    //handle success
                                    //console.log(response);
                                    button.classList.remove('loading--');
                                    if(response.data.success === true){
                                        if(response.data.datas){
                                            downloadURI(response.data.datas, title);
                                        }
                                    }
                                }).catch(function (response) {
                                    //handle error
                                    console.log('err', response);
                                    button.classList.remove('loading--');
                                });
                            }
                        });
                    }
                }

            });
        }

        let places = document.querySelectorAll('.c-eventList__place');
        if(places && places.length > 0){
            places.forEach(place=>{
                const classList = place.querySelector('.c-place__category span').className;
                Plan.newClassStyle(classList,place.querySelector('.c-place__category i') );
               Plan.newClassStyle(classList,place.querySelector('.c-place__category span') );
            });
        }
    }
}

export default Transactions;