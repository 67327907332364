/**
 ### Gestion du custom cursor
 - const cursor = new Cursor({
		minWidth: '1024px',
		pointer: "hidden",
		framerate: 33,
		ignore: '.bouton'
	});
 - Un wrapper général avec la class '.cursor__wrapper'
 - Au clic le wrapper prend une class '.cursor--click'
 - Au rollOver le wrapper prend une class '.cursor--link'
 - **data-cursor-color="#ffffff"** ou n'importe quel couleur hexadécimale
 - **data-cursor-hover** pour forcer l'effet du hover
 -
 */
export class Cursor {
    constructor({
                    minWidth = '1024px',
                    delta,
                    framerate = 10,
                    colorDefault = "#e6223d",
                    pointer = "auto",
                    ignore = []
                }) {
        //responsive
        this.framerate = framerate;
        this.colorDefault = colorDefault;
        this.minWidth = minWidth;
        this.mq = window.matchMedia(`screen and (min-width:${this.minWidth})`);
        this.pointer = pointer;
        this.ignore = ignore;
        this.path = "/wp-content/themes/olympia/dist/assets/img/";


        //
        this.delta = delta;

        //
        this.cursorDatas = {
            mouseX: 0,
            mouseY: 0,
            pageX: window.pageXOffset || window.scrollX,
            pageY: window.pageYOffset || window.scrollY,
        };

        this.markup = `
		<div class="cursor__wrapper">
		  <div class="cursor__pointer" /></div>
		</div>`;
        this.markupHeader = `
		<div class="cursor__wrapper header--">
		  <div class="cursor__pointer" /></div>
		</div>`;

        this.init();
    }

    init() {
        // Responsive resetCursor on tablet/mobil, create for deskop
        this.mq.matches ? this.createCursor() : null;

        this.mq.addListener(() => {
            this.mq.matches ? this.createCursor() : this.resetCursor();
        });
    }

    calcDelta() {
        return this.delta = -this.cursor.clientWidth / 2;
    }

    createCursor() {
        this.insertMarkup();
        this.cursorHeader = document.querySelector('header .cursor__wrapper.header--');
        this.cursor = document.querySelector('.app .cursor__wrapper:not(.header--)');

        //reset cursor
        this.cursor.style.left = 0;
        this.cursor.style.top = 0;
        this.cursor.style.position = "fixed";

        this.cursorHeader.style.left = 0;
        this.cursorHeader.style.top = 0;
        this.cursorHeader.style.position = "fixed";
        this.calcDelta();

        document.addEventListener('mousemove', (event) => {
            if (event.target.closest('.swiper-wrapper')) {
                this.cursorAnim(event);
            } else {
                this.cursorAnim(event, false);
            }
        }, true);


        this.hiddenNativeCursor();
    }

    insertMarkup() {
        const app = document.querySelector('.app');
        app.insertAdjacentHTML('beforeend', this.markup);
        const header = document.querySelector('header.header >.container');
        header.insertAdjacentHTML('afterbegin', this.markupHeader);
    }

    resetCursor() {
        this.cursor.remove();

        document.removeEventListener('mousemove', (event) => {
            if (event.target.closest('.swiper-wrapper')) {
                this.cursorAnim(event);
            } else {
                this.cursorAnim(event, false);
            }
        }, true);

        this.seeNativeCursor()
    }

    hiddenNativeCursor() {
        if (this.pointer === "hidden") {
            document.documentElement.classList.add('cursor__hiddenForAll');
        } else {
            this.seeNativeCursor();
        }
    }

    seeNativeCursor() {
        document.documentElement.classList.remove('cursor__hiddenForAll');
    }

    cursorAnim(event, delay = true) {
        this.cursorDatas.mouseX = event.clientX;
        this.cursorDatas.mouseY = event.clientY;

        let posX = this.cursorDatas.mouseX + this.delta;
        let posY = this.cursorDatas.mouseY + this.delta;

        if (!delay) {
            this.cursor.style.left = `${posX}px`;
            this.cursor.style.top = `${posY}px`;
            //this.cursor.style.transform = `translate(${posX}px, ${posY}px)`;
            this.cursorHeader.style.left = `${posX}px`;
            this.cursorHeader.style.top = `${posY}px`;
        } else {
            setTimeout(() => {
                this.cursor.style.left = `${posX}px`;
                this.cursor.style.top = `${posY}px`;
                //this.cursor.style.transform = `translate(${posX}px, ${posY}px)`;
                this.cursorHeader.style.left = `${posX}px`;
                this.cursorHeader.style.top = `${posY}px`;
            }, this.framerate);
        }
        // check els
        this.mouseOverEvents(event);
    }

    mouseOverEvents(event) {
        let links =
            event.target.closest('button:not(.c-button:not(.linkmini--)):not([data-tooltip]):not(.f-agenda__navigation-prev):not(.f-agenda__navigation-next):not(.toggle-button-icon)') || event.target.closest('a:not(.c-button:not(.linkmini--)):not([data-tooltip])') || event.target.closest('input:not(.c-button:not(.linkmini--)):not([readOnly])') || event.target.closest('[data-cursor-hover]:not(.c-button)') || event.target.closest('.c-stickyEvent__select') || event.target.closest('.c-calendar__select') || event.target.closest('.c-languages') || event.target.closest('.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)') || event.target.closest('.c-modal__backdrop') || event.target.closest('.plan-places') || event.target.closest('#fosse-area') || event.target.closest('.editable-input') || event.target.closest('.form-select:not(.readonly--)');

        // if links
        if (links) {
            this.cursor.classList.add('cursor--link');
            if (event.target.closest('.header[data-sticky="true"] >.container')) {
                this.cursor.classList.add('cursor--link-hidden');
                this.cursorHeader.classList.add('cursor--link-visible');
                this.cursorHeader.classList.add('cursor--link');
            } else if (event.target.closest('.c-img') || event.target.closest('.c-card__img-ctn picture')) {
                this.cursor.classList.add('cursor--link-zindex');
            } else if (event.target.closest('[data-cursor-disabled]') || event.target.closest('.plan-places')) {
                this.cursor.classList.add('cursor--link-zindexminus');
            }
        } else {
            this.cursor.classList.remove('cursor--link');
            this.cursor.classList.remove('cursor--link-zindex');
            this.cursor.classList.remove('cursor--link-hidden');
            this.cursorHeader.classList.remove('cursor--link-hidden');
            this.cursorHeader.classList.remove('cursor--link-visible');
            this.cursorHeader.classList.remove('cursor--link');
            this.cursor.classList.remove('cursor--link-zindexminus');
        }

        let slider = event.target.closest('.swiper-wrapper');
        if (slider) {
            if (!slider.classList.contains('noDragCursor')) {
                let slides = slider.querySelectorAll('.swiper-slide');
                if (slides.length > 1) {
                    this.cursor.classList.add('cursor--drag');
                }
            }
        } else {
            this.cursor.classList.remove('cursor--drag');
        }

        if (event.target.closest('[data-tooltip]')) {
            this.cursor.classList.add('cursor--tooltip');
            const text = event.target.closest('[data-tooltip]').dataset.tooltip;
            if (document.querySelector('.app> .cursor__wrapper .cursor__pointer')) {
                document.querySelector('.app> .cursor__wrapper .cursor__pointer').innerHTML = text ? '<span>' + text + '</span> <i class="icon-arrow-long-right"></i>' : '<i class="icon-arrow-long-right"></i>';
            }
            document.addEventListener('scroll', (event) => {
                this.cursor.classList.remove('cursor--tooltip');
                if (document.querySelector('.app> .cursor__wrapper .cursor__pointer')) {
                    document.querySelector('.app> .cursor__wrapper .cursor__pointer').innerHTML = "";
                }
            }, true);
        } else {
            this.cursor.classList.remove('cursor--tooltip');
            if (document.querySelector('.app> .cursor__wrapper .cursor__pointer')) {
                document.querySelector('.app> .cursor__wrapper .cursor__pointer').innerHTML = "";
            }
        }

        const toggleButtonIcon = event.target.closest('.toggle-button-icon');
        if (toggleButtonIcon) {
            const parent = toggleButtonIcon.parentElement.parentElement;
            if (parent.querySelector('.long')) {
                if (parent.classList.contains('active')) {
                    this.cursor.classList.add('cursor--minus');
                } else {
                    this.cursor.classList.add('cursor--plus');
                }
            }
        } else {
            if (this.cursor.classList.contains('cursor--plus')) {
                this.cursor.classList.remove('cursor--plus');
            }
            if (this.cursor.classList.contains('cursor--minus')) {
                this.cursor.classList.remove('cursor--minus');
            }
        }

        // remove custom cursor on these elements
        if (event.target.closest('iframe') || event.target.closest('.header__menu-main') || event.target.closest('.c-button:not(.linkmini--)') || event.target.closest('.swiper-pagination-bullet-active') || event.target.closest('.f-agenda__navigation-prev') || event.target.closest('.f-agenda__navigation-next')) {
            this.cursor.classList.add('cursor--hidden');
        } else {
            this.cursor.classList.remove('cursor--hidden');
        }
    }

    mouseClicEvents() {
        this.cursor.classList.add('cursor--click');

        let timer = setTimeout(() => {
            this.cursor.classList.remove('cursor--click');
            clearTimeout(timer);
        }, 300);
    }
}